import React, { useState, useEffect} from "react";
import { Modal, Button, Card } from "antd";
import { CloseSquareFilled, LinkOutlined } from "@ant-design/icons";
import QRCode from "qrcode.react";
import styles from "./ShareSurveyModal.module.css";
import c from "classnames/bind";
import { BASEURL } from "../../../../../constants";
import handleNetworkErrors from "../../../handleNetworkErrors";
import axios, { Routes } from "../../../../../services/API"

const cx = c.bind(styles);

const ShareSurveyModal = ({
  handleOk,
  handleCancel,
  isVisible,
  isPublish,
  surveyToken,
  surveyAccess,
  surveyMode,
  surveyId,
  clientId,
  surveyType,
  survey,
  setOpenToken,
  token
}) => {

const [surveyLink, setSurveyLink] = useState('');
const isOpenModerated = surveyType && surveyMode && surveyAccess
let openModToken = survey?.openSurvey?.surveyLinks[survey.openSurvey.surveyLinks.length-1]?.link || token
const link = isOpenModerated ? `https://userqual.com/attemptSurvey/${surveyToken}?token=${openModToken}` : `https://userqual.com/attemptSurvey/${surveyToken}`


useEffect(() => {
  setSurveyLink(link)
}, [isVisible])

  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  const generateOpenModLink = async () => {
    const {url, method} = Routes.client.generateOpenModeratedLink(survey.clientId, survey._id)
    try {
      debugger
      const {data} = await axios[method](url);
      let openModToken = data.newSurveyLink.link
      let link = `https://userqual.com/attemptSurvey/${surveyToken}?token=${openModToken}`
      setSurveyLink(link)
      setOpenToken(openModToken)
    } catch (err) {
      handleNetworkErrors(err);
    }
  }

  const footer = isPublish
    ? [
        <CancelButton handleCancel={handleCancel} />,
        <PublishButton handleOk={handleOk} />,
      ]
    : [<DoneButton handleOk={handleOk} />];
  //TODO: need to create frontend endpoint using AddSurvey.jsx and make a backend call to  and setup a proper route on Routes.jsx
  return (
    <Modal
      className={cx("survey-state-modal")}
      centered={true}
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={true}
      width={700}
      footer={footer}
      closeIcon={
        <CloseSquareFilled
          style={{
            fontSize: 24,
            color: "#dddddd",
          }}
        />
      }
    >
      <div style={{ fontSize: "1rem", fontWeight: "bold" }}>Publish survey</div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "20px",
          padding: "12px 0",
        }}
      >
        {isPublish ? (
          <div>
            Are you sure you want to publish this survey? All the users added
            will be notified about this survey. You can also copy the below link
            and share it with other users. You can later copy this link from
            published survey page.
          </div>
        ) : (
          <div>
            Copy the below link to share this survey with other users. Users
            will need to login with mobile OTP or email to take the survey. You
            can also download the QR code to access the survey through a QR
            scan.
          </div>
        )}
       <QRCode id="qrCodeEl" size={100} value={surveyLink}/>
      </div>
      <Card style={{ backgroundColor: "#EEEEEE", borderRadius: "5px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            textAlign: "center",
          }}
        >
          <LinkOutlined
            style={{
              color: "#FE9780",
              margin: "auto 0",
              padding: "0 20px 0 0",
            }}
          />
          <a
            style={{
              overflowWrap: "anywhere",
              textAlign: "left",
              fontSize: "0.9em",
            }}
            href={surveyLink}
          >
            {surveyLink}
          </a>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "12px 0 0 0",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ padding: "0 5px 0 20px", margin: "auto" }}>
              <Button
                style={{
                  margin: "auto",
                  color: "white",
                  backgroundColor: "#FE9780",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(surveyLink);
                  }
                }
              >
                Copy Link
              </Button>
            </div>
            <div style={{ margin: "auto" }}>
              <Button
                style={{
                  margin: "auto",
                  color: "white",
                  backgroundColor: "#FE9780",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  downloadQRCode();
                }}
              >
                Download QR Code
              </Button>
            </div>
           {isOpenModerated && !isPublish && <div style={{ padding: "0 5px 0 20px", margin: "auto" }}>
              <Button
                style={{
                  margin: "auto",
                  color: "white",
                  backgroundColor: "#FE9780",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  generateOpenModLink()
                  }
                }
              >
                Generate New Link
              </Button>
            </div>
            }
          </div>
        </div>
      </Card>
    </Modal>
  );
};

const PublishButton = ({ handleOk }) => {
  return (
    <Button
      key="submit"
      style={{
        backgroundColor: "#073D6D",
        color: "white",
        borderRadius: "5px",
        border: "none",
      }}
      onClick={handleOk}
    >
      {"Publish"}
    </Button>
  );
};

const CancelButton = ({ handleCancel }) => {
  return (
    <Button
      key="back"
      style={{ borderRadius: "5px", border: "none" }}
      onClick={handleCancel}
    >
      Cancel
    </Button>
  );
};

const DoneButton = ({ handleOk }) => {
  return (
    <Button
      key="submit"
      style={{
        backgroundColor: "#073D6D",
        color: "white",
        borderRadius: "5px",
        border: "none",
      }}
      onClick={handleOk}
    >
      {"Done"}
    </Button>
  );
};

export default ShareSurveyModal;
