import { useState, useEffect } from "react";
import { capitalizeFirstCharacter, Sum } from "../../../utils";
import { months } from "../../../constants";
import getBranchingErrors from "../branching-logic/handleErrors";

const getSetupErrors = (survey) => {
  const err = [];
  if (!survey.name || survey.name === "") {
    err.push({
      message: "Please enter Survey name.",
      description: "Please enter Survey name.",
    });
  }

  if (survey.surveyType === undefined) {
    err.push({
      message:
        "Please choose if it’s a Qualitative (Video) Survey or a Quantitative (Online) Survey.",
      description:
        "Please choose if it’s a Qualitative (Video) Survey or a Quantitative (Online) Survey.",
    });
  }

  if (survey.surveyType === true && survey.mode === undefined) {
    err.push({
      message:
        "Please choose if the Qualitative (Video) Survey would be Moderated (conducting by someone) or Unmoderated (self-conducted).",
      description:
        "Please choose if the Qualitative (Video) Survey would be Moderated (conducting by someone) or Unmoderated (self-conducted).",
    });
  }

  if (
    survey.surveyType === true &&
    survey.mode !== undefined &&
    survey.device === undefined
  ) {
    err.push({
      message:
        "Please choose if you would like the Survey to be taken on a Desktop or on a Mobile phone.",
      description:
        "Please choose if you would like the Survey to be taken on a Desktop or on a Mobile phone.",
    });
  }

  if (
    survey.surveyType === true &&
    survey.mode === true &&
    survey.device !== undefined &&
    survey.setting === undefined
  ) {
    err.push({
      message:
        "Please choose if the Survey would conducted in an Individual or Group setting.",
      description:
        "Please choose if the Survey would conducted in an Individual or Group setting.",
    });
  }

  if (
    (survey.surveyType === true &&
      survey.mode === true &&
      survey.device !== undefined &&
      survey.setting !== undefined &&
      survey.timingType === undefined) ||
    (survey.surveyType === true &&
      survey.mode === false &&
      survey.device !== undefined &&
      survey.timingType === undefined)
  ) {
    err.push({
      message:
        "Please choose how long you would like to conduct the Survey for.",
      description:
        "Please choose how long you would like to conduct the Survey for.",
    });
  }

  return err;
};

const getCriteriaErrors = (survey, adminQuestions) => {
  const err = [];

  const getAdminQuestionText = (id) => {
    if (adminQuestions && adminQuestions.length > 0)
      return capitalizeFirstCharacter(
        adminQuestions.filter((s) => s["_id"] === id)[0].clientField
      );
    else return "Unnamed criteria";
  };

  if (!(survey.mode && survey.surveyType && survey.setting)) {
    survey.criteria.forEach((criterion) => {
      if (criterion?.ratios?.length && Sum(criterion.ratios) != 100) {
        err.push({
          message: `Ratios for selected criteria, "${getAdminQuestionText(
            criterion.questionId
          )}" do not add upto 100%.`,
          description: `Please select ratio(s) for criteria, "${getAdminQuestionText(
            criterion.questionId
          )}" such that it adds upto 100%".`,
        });
      }
    });
  } else if (survey?.groups?.length) {
    survey.groups.forEach((group) => {
      group.criteria.forEach((criterion) => {
        if (criterion?.ratios?.length && Sum(criterion.ratios) != 100) {
          err.push({
            message: `Ratios for selected criteria, "${getAdminQuestionText(
              criterion.questionId
            )}" in group, "${group.name}" do not add upto 100%.`,
            description: `Please select ratio(s) for criteria, "${getAdminQuestionText(
              criterion.questionId
            )}" in group, "${group.name}" such that it adds upto 100%".`,
          });
        }
      });
    });
  }

  survey.criteria.forEach((e) => {
    if (e.answer.length === 0) {
      err.push({
        message: `There is no selected option for criteria, "${getAdminQuestionText(
          e.questionId
        )}".`,
        description: `Please select option(s) for criteria, "${getAdminQuestionText(
          e.questionId
        )}".`,
      });
    }
  });

  survey.screeningQuestions.forEach((e) => {
    if (e.answer.length === 0) {
      err.push({
        message: `There is no qualified option for additional screening question, "${e.questionObject.text}".`,
        description: `Please select option(s) for additional screening question, "${e.questionObject.text}".`,
      });
    }
  });

  return err;
};

const getParticipantsErrors = (survey) => {
  const err = [];

  if (
    !survey.useUserqualUsers &&
    !survey.useImportedUsers &&
    !survey.usePanel &&
    !survey.surveyAccess
  ) {
    err.push({
      message: "There are no users for the survey.",
      description: "Please select atleast one of the options.",
    });
  }

  if (!!survey.useImportedUsers && !survey.clientProvidedUsers?.length) {
    err.push({
      message: "There are no imported users for the survey.",
      description: "Please import user emails for the survey",
    });
  }

  if (!!survey.usePanel && !survey.panelUsers?.length) {
    err.push({
      message: "No users have been added from the previous survey.",
      description: "Please add users from the previous survey.",
    });
  }

  return err;
};

const getQuestionsErrors = (survey) => {
  const err = [];

  if (survey.surveyType === false && survey.questions.length === 0) {
    err.push({
      message: `Questions missing.`,
      description: `You need to add atleast one question.`,
    });
  }

  if (
    survey.surveyType === true &&
    survey.mode === false &&
    survey.questions.length === 0
  ) {
    err.push({
      message: `Questions missing.`,
      description: `You need to add atleast one question.`,
    });
  }

  survey.questions.forEach((question, idx) => {
    const num = idx + 1;

    if (question.text === "" && question.type !== "netPromotorScore") {
      err.push({
        message: `There is no text for question no. ${num}`,
        description: `Please provide some text for question no. ${num}`,
      });
    }

    if (question.type === "likert") {
      if (question.options.likertStartLabel === "") {
        err.push({
          message: `There is no start label for question no. ${num}`,
          description: `Please provide start label for question no. ${num}`,
        });
      }

      // if (question.options.likertMiddleLabel === "") {
      //   err.push({
      //     message: `There is no middle label for question no. ${num}`,
      //     description: `Please provide middle label for question no. ${num}`,
      //   });
      // }

      if (question.options.likertEndLabel === "") {
        err.push({
          message: `There is no end label for question no. ${num}`,
          description: `Please provide end label for question no. ${num}`,
        });
      }
    }

    if (question.type === "slider") {
      if (question.options.sliderStartLabel === "") {
        err.push({
          message: `There is no start label for question no. ${num}`,
          description: `Please provide start label for question no. ${num}`,
        });
      }

      // if (question.options.sliderMiddleLabel === "") {
      //   err.push({
      //     message: `There is no middle label for question no. ${num}`,
      //     description: `Please provide middle label for question no. ${num}`,
      //   });
      // }

      if (question.options.sliderEndLabel === "") {
        err.push({
          message: `There is no end label for question no. ${num}`,
          description: `Please provide end label for question no. ${num}`,
        });
      }
    }

    if (question.type === "multiChoices" || question.type === "checkBoxes") {
      question.options.multiChoices.forEach((opt) => {
        if (opt.label === "") {
          err.push({
            message: `There is no label for question no. ${num}`,
            description: `Please provide a label for question no. ${num}`,
          });
        }
      });
    }

    if (
      question.type === "imageMultiChoices" ||
      question.type === "imageCheckBoxes"
    ) {
      question.options.imageMultiChoices.forEach((opt) => {
        if (opt.imageLabel === "") {
          err.push({
            message: `There is no label for question no. ${num}`,
            description: `Please provide a label for question no. ${num}`,
          });
        }
        if (opt.imageData === "") {
          err.push({
            message: `There is no image for question no. ${num}`,
            description: `Please provide an image for question no. ${num}`,
          });
        }
      });
    }

    if (question.type === "imageStarRatingChoices") {
      question.options.imageStarRatingChoices.forEach((opt) => {
        if (opt.imageLabel === "") {
          err.push({
            message: `There is no label for question no. ${num}`,
            description: `Please provide a label for question no. ${num}`,
          });
        }
        if (opt.imageData === "") {
          err.push({
            message: `There is no image for question no. ${num}`,
            description: `Please provide an image for question no. ${num}`,
          });
        }
      });
    }

    if (question.type === "videoStarRatingChoices") {
      question.options.videoStarRatingChoices.forEach((opt) => {
        if (opt.videoLabel === "") {
          err.push({
            message: `There is no label for question no. ${num}`,
            description: `Please provide a label for question no. ${num}`,
          });
        }
        if (opt.videoData === "") {
          err.push({
            message: `There is no video for question no. ${num}`,
            description: `Please provide a video for question no. ${num}`,
          });
        }
      });
    }

    if (question.type === "association") {
      question.options.associationChoices.forEach((opt) => {
        if (opt.association === "") {
          err.push({
            message: `There is no association for question no. ${num}`,
            description: `Please provide association for label for question no. ${num}`,
          });
        }
        if (opt.label === "") {
          err.push({
            message: `There is no label for question no. ${num}`,
            description: `Please provide a label for question no. ${num}`,
          });
        }
      });
    }

    if (question.type === "imageAssociation") {
      question.options.imageAssociationChoices.forEach((opt) => {
        if (opt.association === "") {
          err.push({
            message: `There is no association for question no. ${num}`,
            description: `Please provide association for label for question no. ${num}`,
          });
        }
        if (opt.imageData === "") {
          err.push({
            message: `There is no image for question no. ${num}`,
            description: `Please provide an image for question no. ${num}`,
          });
        }
      });
    }

    if (question.type === "videoAssociation") {
      question.options.videoAssociationChoices.forEach((opt) => {
        if (opt.association === "") {
          err.push({
            message: `There is no association for question no. ${num}`,
            description: `Please provide association label for question no. ${num}`,
          });
        }
        if (opt.videoData === "") {
          err.push({
            message: `There is no video for question no. ${num}`,
            description: `Please provide a video for question no. ${num}`,
          });
        }
      });
    }

    if (question.type === "adAnalysis") {
      question.options.adAnalysisChoices.forEach((opt) => {
        if (!opt.videoType) {
          err.push({
            description: `Please provide video type for question no. ${num}`,
          });
        }
        if (opt.videoData === "") {
          err.push({
            description: `Please provide a video for question no. ${num}`,
          });
        }
      });

      if (!question.options.adAnalysisData.url) {
        err.push({
          description: `Please combine the video of question no. ${num}`,
        });
      }
    }
  });
  return err;
};

const getPublishErrors = (survey) => {
  const err = [];

  if (
    survey.surveyType === true &&
    survey.mode === true &&
    survey.setting === false &&
    !survey.surveyAccess &&
    !survey.moderatedSlots
  ) {
    err.push({
      message: `Please select a date and time for conducting the Survey.`,
      description: `Please select a date and time for conducting the Survey.`,
    });
  }
  if (
    survey.moderatedSlots &&
    Object.keys(survey.moderatedSlots).length === 0
  ) {
    err.push({
      message: `Please select a date and time for conducting the Survey.`,
      description: `Please select a date and time for conducting the Survey.`,
    });
  }

  if (survey.surveyType && survey.mode && survey.moderatedSlots) {
    let totalSelectedSlots = 0;
    if (survey.setting) {
      totalSelectedSlots = Object.keys(survey.moderatedSlots).reduce(
        (sum, slot) =>
          Object.keys(survey.moderatedSlots[slot]).length * 5 + sum,
        0
      );
    } else {
      totalSelectedSlots = Object.keys(survey.moderatedSlots).reduce(
        (sum, slot) => Object.keys(survey.moderatedSlots[slot]).length + sum,
        0
      );
    }
    if (survey.numberOfParticipants > totalSelectedSlots) {
      err.push({
        message: `Total number of participants are ${survey.numberOfParticipants} and selected slots are ${totalSelectedSlots}`,
        description: `Total number of participants are ${survey.numberOfParticipants} and selected slots are ${totalSelectedSlots}`,
      });
    }
  }

  if (survey.moderatedSlots) {
    Object.keys(survey.moderatedSlots).forEach((date) => {
      if (Object.keys(survey.moderatedSlots[date]).length === 0) {
        const [day, month, year] = date.split("/");
        err.push({
          message: `Please select slots for ${
            months[parseInt(month) - 1]
          } ${day} ${year}.`,
          description: `Please select slots for ${
            months[parseInt(month) - 1]
          } ${day} ${year}.`,
        });
      }
    });
  }

  return err;
};

const useErrors = (survey, adminQuestions) => {
  const [errors, setErrors] = useState({
    setupErrors: [],
    questionsErrors: [],
    criteriaErrors: [],
    participantsErrors: [],
    publishErrors: [],
    branchingErrors: [],
  });

  useEffect(() => {
    if (survey) {
      const setupErrors = getSetupErrors(survey);
      const criteriaErrors = getCriteriaErrors(survey, adminQuestions);
      const questionsErrors = getQuestionsErrors(survey);
      const participantsErrors = getParticipantsErrors(survey);
      const publishErrors = getPublishErrors(survey);
      const branchingErrors = getBranchingErrors(survey.rules);
      setErrors((s) => ({
        ...s,
        setupErrors,
        criteriaErrors,
        questionsErrors,
        participantsErrors,
        publishErrors,
        branchingErrors,
      }));
    }
  }, [survey, adminQuestions]);

  return errors;
};

export default useErrors;
