import React from "react";
import { merge } from "lodash";
import AgoraRTC from "agora-rtc-sdk";
import io from "socket.io-client";
import "./canvas.css";
import "../../assets/fonts/css/icons.css";
import VideoStreamMerger from "video-stream-merger";
import { useHistory } from "react-router-dom";
import { BASEURL } from "../../constants.js";

const tile_canvas = {
  1: ["span 12/span 24"],
  2: ["1 / 1 / span 12 / span 12", "1 / 13 / span 12 / span 12"],
  3: [
    "1 / 13 / span 6 / span 12",
    "7 / 1 / span 6 / span 12",
    "1 / 1 / span 6 / span 12",
  ],
  4: [
    "1 / 13 / span 6 / span 12",
    "7 / 1 / span 6 / span 12",
    "7 / 13 / span 6 / span 12",
    "1 / 1 / span 6 / span 12",
  ],
  5: [
    "1 / 9 / span 6 / span 8",
    "1 / 17 / span 6 / span 8",
    "7 / 1 / span 6 / span 8",
    "7 / 9 / span 6 / span 8",
    "1 / 1 / span 6 / span 8",
  ],
  6: [
    "1 / 9 / span 6 / span 8",
    "1 / 17 / span 6 / span 8",
    "7 / 1 / span 6 / span 8",
    "7 / 9 / span 6 / span 8",
    "7 / 17 / span 6 / span 8",
    "1 / 1 / span 6 / span 8",
  ],
  7: [
    "1 / 7 / span 6 / span 6",
    "1 / 13 / span 6 / span 6",
    "1 / 19 / span 6 / span 6",
    "7 / 1 / span 6 / span 6",
    "7 / 7 / span 6 / span 6",
    "7 / 13 / span 6 / span 6",
    "1 / 1 / span 6 / span 6",
  ],
  8: [
    "1 / 7 / span 6 / span 6",
    "1 / 13 / span 6 / span 6",
    "1 / 19 / span 6 / span 6",
    "7 / 1 / span 6 / span 6",
    "7 / 7 / span 6 / span 6",
    "7 / 13 / span 6 / span 6",
    "7 / 19 / span 6 / span 6",
    "1 / 1 / span 6 / span 6",
  ],
  9: [
    "1 / 7 / span 4 / span 6",
    "1 / 13 / span 4 / span 6",
    "1 / 19 / span 4 / span 6",
    "5 / 1 / span 4 / span 6",
    "5 / 7 / span 4 / span 6",
    "5 / 13 / span 4 / span 6",
    "5 / 19 / span 4 / span 6",
    "9 / 1 / span 4 / span 6",
    "1 / 1 / span 4 / span 6",
  ],
  10: [
    "1 / 7 / span 4 / span 6",
    "1 / 13 / span 4 / span 6",
    "1 / 19 / span 4 / span 6",
    "5 / 1 / span 4 / span 6",
    "5 / 7 / span 4 / span 6",
    "5 / 13 / span 4 / span 6",
    "5 / 19 / span 4 / span 6",
    "9 / 1 / span 4 / span 6",
    "9 / 7 / span 4 / span 6",
    "1 / 1 / span 4 / span 6",
  ],
  11: [
    "1 / 7 / span 4 / span 6",
    "1 / 13 / span 4 / span 6",
    "1 / 19 / span 4 / span 6",
    "5 / 1 / span 4 / span 6",
    "5 / 7 / span 4 / span 6",
    "5 / 13 / span 4 / span 6",
    "5 / 19 / span 4 / span 6",
    "9 / 1 / span 4 / span 6",
    "9 / 7 / span 4 / span 6",
    "9 / 13 / span 4 / span 6",
    "1 / 1 / span 4 / span 6",
  ],
  12: [
    "1 / 7 / span 4 / span 6",
    "1 / 13 / span 4 / span 6",
    "1 / 19 / span 4 / span 6",
    "5 / 1 / span 4 / span 6",
    "5 / 7 / span 4 / span 6",
    "5 / 13 / span 4 / span 6",
    "5 / 19 / span 4 / span 6",
    "9 / 1 / span 4 / span 6",
    "9 / 7 / span 4 / span 6",
    "9 / 13 / span 4 / span 6",
    "9 / 19 / span 4 / span 6",
    "1 / 1 / span 4 / span 6",
  ],
};
const WIDTH = [0, 400, 800, 0, 400, 800];
const HEIGHT = [0, 0, 0, 300, 300, 300];
const uploader = (url, uploadURL, props) => {
  return {
    init: function (data) {
      this.socket = io(this.url);
      let ss = this.socket;
      ss.on("connect", () => {
        ss.emit("subscribe", {
          ...this.data,
          ...data,
        });
        ss.on("host-leave", () => {
          this.onHostLeave();
        });
      });
      ss.on("recieve-question", (qData) => {
        if (props.showQuestionPopup) {
          props.showQuestionPopup(qData.question);
        }
      });
      ss.on("recieve-answer", (aData) => {
        if (props.showAnswer && props.isClient) {
          props.showAnswer(aData);
        }
      });
    },
    onHostLeave: () => {
      //do something
    },
    startUpload: function () {
      let ss = this.socket;
      ss.emit("subscribe-upload", {
        ...this.data,
      });
    },
    upload: function (chunk) {
      let ss = this.socket;
      ss.emit("data_available", {
        ...this.data,
        chunk,
      });
    },
    endUpload: function () {
      let ss = this.socket;
      ss.emit("leave-upload", {
        ...this.data,
      });
      console.log("upload complete");
    },
    hostLeave: function () {
      let ss = this.socket;
      ss.emit("host-leave", {
        ...this.data,
      });
      console.log("host left");
    },
    onPublishQuestion: function (question) {
      console.log("on update", this);
      let ss = this.socket;
      ss.emit("send-question", {
        ...this.data,
        question: question,
      });
    },
    onSendAnswer: function (data) {
      let ss = this.socket;
      ss.emit("send-answer", {
        ...this.data,
        answer: data,
      });
    },
    url: url,
    data: {
      uploadURL: uploadURL,
    },
  };
};

/**
 * @prop appId uid
 * @prop transcode attendeeMode videoProfile channel baseMode
 */
class AgoraCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.client = {};
    this.localStream = {};
    this.shareClient = {};
    this.shareStream = {};
    this.mediaRecorder = {};
    this.merger = {};
    this.curStream = 0;
    this.state = {
      displayMode: "pip",
      streamList: [],
      readyState: false,
      isRecordingStarted: false,
      isRecordingEnded: false,
    };
    this.isClient = props.isClient;
    this.isOneToOne = props.isOneToOne;
    this.notes = props.notes;
    this.setNotes = props.setNotes;
    this.uploader = uploader(`${BASEURL}/stream`, props.uploadURL, props);
  }

  componentWillMount() {
    let $ = this.props;
    let ex = this;
    this.uploader.onHostLeave = () => {
      const response = {
        userId: localStorage.getItem("user"),
        answers: [],
        filePath: $.channel + ".json",
      };
      $.handleEndSurvey($.surveyId, response).then(() => {
        ex.handleExit();
      });
    };
    // init AgoraRTC local client
    this.client = AgoraRTC.createClient({ mode: $.transcode });
    this.client.init($.appId, () => {
      console.log("AgoraRTC client initialized");
      this.subscribeStreamEvents();
      this.client.join($.appId, $.channel, $.uid, (uid) => {
        if(this.props.setOpenUID) {   
          this.props.setOpenUID(uid)
        }
        console.log("User " + uid + " join channel successfully");
        console.log("At " + new Date().toLocaleTimeString());
        // create local stream
        // It is not recommended to setState in function addStream
        this.localStream = this.streamInit(uid, $.attendeeMode, $.videoProfile);
        this.localStream.init(
          () => {
            if ($.attendeeMode !== "audience") {
              this.addStream(this.localStream, true);
              this.client.publish(this.localStream, (err) => {
                console.log("Publish local stream error: " + err);
              });
            }
            this.setState({ readyState: true });
          },
          (err) => {
            console.log("getUserMedia failed", err);
            this.setState({ readyState: true });
          }
        );
      });
    });
  }

  componentDidMount() {
    // add listener to control btn group
    let canvas = document.querySelector("#ag-canvas");
    let btnGroup = document.querySelector(".ag-btn-group");
    canvas.addEventListener("mousemove", () => {
      if (global._toolbarToggle) {
        clearTimeout(global._toolbarToggle);
      }
      btnGroup.classList.add("active");
      global._toolbarToggle = setTimeout(function () {
        btnGroup.classList.remove("active");
      }, 2000);
    });
    this.uploader.init();
  }

  // componentWillUnmount () {
  //     // remove listener
  //     let canvas = document.querySelector('#ag-canvas')
  //     canvas.removeEventListener('mousemove')
  // }

  componentDidUpdate() {
    // rerendering
    let canvas = document.querySelector("#ag-canvas");
    // pip mode (can only use when less than 4 people in channel)
    if (this.state.displayMode === "pip") {
      let no = this.state.streamList.length;
      if (no > 4) {
        this.setState({ displayMode: "tile" });
        return;
      }
      this.state.streamList.map((item, index) => {
        let id = item.getId();
        let dom = document.querySelector("#ag-item-" + id);
        if (!dom) {
          dom = document.createElement("section");
          dom.setAttribute("id", "ag-item-" + id);
          dom.setAttribute("class", "ag-item");
          canvas.appendChild(dom);
          item.play("ag-item-" + id);
        }
        if (index === no - 1) {
          dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);
        } else {
          dom.setAttribute(
            "style",
            `grid-area: span 3/span 4/${4 + 3 * index}/25;
                    z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
          );
        }

        item.player.resize && item.player.resize();
      });
    }
    // tile mode
    else if (this.state.displayMode === "tile") {
      let no = this.state.streamList.length;
      this.state.streamList.map((item, index) => {
        let id = item.getId();
        let dom = document.querySelector("#ag-item-" + id);
        if (!dom) {
          dom = document.createElement("section");
          dom.setAttribute("id", "ag-item-" + id);
          dom.setAttribute("class", "ag-item");
          canvas.appendChild(dom);
          item.play("ag-item-" + id);
        }
        dom.setAttribute("style", `grid-area: ${tile_canvas[no][index]}`);
        item.player.resize && item.player.resize();
      });
    }
    // screen share mode (tbd)
    else if (this.state.displayMode === "share") {
    }

    //Publish Question
    let $ = this;
    if (
      this.props.triggerQuestionPublished &&
      this.props.triggerQuestionPublished.trigger
    ) {
      // console.log(
      //   "on update question is here",
      //   this.props.triggerQuestionPublished.question
      // );
      $.uploader.onPublishQuestion(
        this.props.triggerQuestionPublished.question
      );
      this.props.setTriggerQuestionPublished({
        trigger: false,
        question: null,
      });
    }

    //Answer Question - User Side
    if (
      !this.isClient &&
      this.props.triggerSubmitAnswer &&
      this.props.triggerSubmitAnswer.trigger
    ) {
      // console.log("on submit answer", this.props.triggerSubmitAnswer);
      $.uploader.onSendAnswer(this.props.triggerSubmitAnswer.data);
      this.props.setTriggerSubmitAnswer({
        trigger: false,
        data: {},
      });
    }
  }

  componentWillUnmount() {
    this.client && this.client.unpublish(this.localStream);
    this.localStream && this.localStream.close();
    this.client &&
      this.client.leave(
        () => {
          console.log("Client succeed to leave.");
        },
        () => {
          console.log("Client failed to leave.");
        }
      );
  }

  addStreamToMerger(stream) {
    let merger = this.merger;
    console.log("merger,", merger, stream);
    let numStreams = this.state.streamList.length;
    let $ = this;
    merger.addStream(stream, {
      mute: false,
      draw: function (ctx, frame, done) {
        if ($.curStream >= numStreams) $.curStream = 0;
        ctx.drawImage(frame, WIDTH[$.curStream], HEIGHT[$.curStream], 400, 300);
        $.curStream = $.curStream + 1;
        done();
      },
    });
  }

  streamInit = (uid, attendeeMode, videoProfile, config) => {
    let defaultConfig = {
      streamID: uid,
      audio: true,
      video: true,
      screen: false,
    };

    switch (attendeeMode) {
      case "audio-only":
        defaultConfig.video = false;
        break;
      case "audience":
        defaultConfig.video = false;
        defaultConfig.audio = false;
        break;
      default:
      case "video":
        break;
    }

    let stream = AgoraRTC.createStream(merge(defaultConfig, config));
    stream.setVideoProfile(videoProfile);
    return stream;
  };

  subscribeStreamEvents = () => {
    let rt = this;
    rt.client.on("stream-added", function (evt) {
      let stream = evt.stream;
      console.log("New stream added: " + stream.getId());
      console.log("At " + new Date().toLocaleTimeString());
      console.log("Subscribe ", stream);
      rt.client.subscribe(stream, function (err) {
        console.log("Subscribe stream failed", err);
      });
    });

    rt.client.on("peer-leave", function (evt) {
      let stream = evt.stream;
      console.log("Peer has left: " + evt.uid);
      console.log(new Date().toLocaleTimeString());
      console.log(evt);
      if (
        rt.isClient &&
        rt.state.isRecordingStarted &&
        !rt.state.isRecordingEnded
      )
        rt.merger.removeStream(stream.stream);
      rt.removeStream(evt.uid);
    });

    rt.client.on("stream-subscribed", function (evt) {
      let stream = evt.stream;
      console.log("Got stream-subscribed event");
      console.log(new Date().toLocaleTimeString());
      console.log("Subscribe remote stream successfully: " + stream.getId());
      console.log(evt);
      rt.addStream(stream);
      if (
        rt.isClient &&
        rt.state.isRecordingStarted &&
        !rt.state.isRecordingEnded
      )
        setTimeout(() => rt.addStreamToMerger(stream.stream), 1000);
    });

    rt.client.on("stream-removed", function (evt) {
      let stream = evt.stream;
      console.log("Stream removed: " + stream.getId());
      console.log(new Date().toLocaleTimeString());
      console.log(evt);
      if (
        rt.isClient &&
        rt.state.isRecordingStarted &&
        !rt.state.isRecordingEnded
      )
        rt.merger.removeStream(stream.stream);
      rt.removeStream(evt.uid);
    });
  };

  removeStream = (uid) => {
    this.state.streamList.map((item, index) => {
      if (item.getId() === uid) {
        item.close();
        let element = document.querySelector("#ag-item-" + uid);
        if (element) {
          element.parentNode.removeChild(element);
        }
        let tempList = [...this.state.streamList];
        tempList.splice(index, 1);
        this.setState({
          streamList: tempList,
        });
      }
    });
  };

  addStream = (stream, push = false) => {
    let repeatition = this.state.streamList.some((item) => {
      return item.getId() === stream.getId();
    });
    if (repeatition) {
      return;
    }
    if (push) {
      this.setState({
        streamList: this.state.streamList.concat([stream]),
      });
    } else {
      this.setState({
        streamList: [stream].concat(this.state.streamList),
      });
    }
  };

  handleCamera = (e) => {
    e.currentTarget.classList.toggle("off");
    this.localStream.isVideoOn()
      ? this.localStream.disableVideo()
      : this.localStream.enableVideo();
  };

  handleMic = (e) => {
    e.currentTarget.classList.toggle("off");
    this.localStream.isAudioOn()
      ? this.localStream.disableAudio()
      : this.localStream.enableAudio();
  };

  switchDisplay = (e) => {
    if (
      e.currentTarget.classList.contains("disabled") ||
      this.state.streamList.length <= 1
    ) {
      return;
    }
    if (this.state.displayMode === "pip") {
      this.setState({ displayMode: "tile" });
    } else if (this.state.displayMode === "tile") {
      this.setState({ displayMode: "pip" });
    } else if (this.state.displayMode === "share") {
      // do nothing or alert, tbd
    } else {
      console.error("Display Mode can only be tile/pip/share");
    }
  };

  hideRemote = (e) => {
    if (
      e.currentTarget.classList.contains("disabled") ||
      this.state.streamList.length <= 1
    ) {
      return;
    }
    let list;
    let id = this.state.streamList[this.state.streamList.length - 1].getId();
    list = Array.from(
      document.querySelectorAll(`.ag-item:not(#ag-item-${id})`)
    );
    list.map((item) => {
      if (item.style.display !== "none") {
        item.style.display = "none";
      } else {
        item.style.display = "block";
      }
    });
  };

  handleExit = (e) => {
    if (!this.state.readyState) {
      return;
    }
    try {
      this.client && this.client.unpublish(this.localStream);
      this.localStream && this.localStream.close();
      this.client &&
        this.client.leave(
          () => {
            console.log("Client succeed to leave.");
          },
          () => {
            console.log("Client failed to leave.");
          }
        );
      this.isClient && this.uploader.hostLeave();
    } finally {
      this.setState({ readyState: false });
      this.client = null;
      this.localStream = null;
      // redirect to index
      window.location.href = "/";
    }
  };

  startRecording = () => {
    if (this.isClient) {
      this.uploader.startUpload();
      this.merger = new VideoStreamMerger({
        width: 400 * 3,
        height: 300 * 2,
        fps: 25,
        clearRect: true,
        audioContext: null,
      });
      let $ = this;
      this.state.streamList.forEach((item) => $.addStreamToMerger(item.stream));
      this.merger.start();
      let mediaRecorder = new MediaRecorder(this.merger.result, {
        mimeType: "video/webm;codecs=vp8,opus",
      });
      mediaRecorder.ondataavailable = function (event) {
        // console.log("data-available");
        if (event.data.size > 0) {
          $.uploader.upload(event.data);
        }
      };
      mediaRecorder.start(1000);
      this.mediaRecorder = mediaRecorder;
      this.props.setRecordingStartTime(new Date());
      if (typeof this.props.stopRecordingHandler === "function") {
        this.props.stopRecordingHandler();
      }
      this.setState({ isRecordingStarted: true });
    }
  };

  stopRecording = () => {
    if (this.isClient) {
      this.mediaRecorder.stop();
      this.merger.destroy();
      this.uploader.endUpload();
      this.props.setRecordingStartTime(null);
      this.setState({ isRecordingEnded: true });
    }
  };

  render() {
    const style = {
      display: "grid",
      gridGap: "10px",
      alignItems: "center",
      justifyItems: "center",
      gridTemplateRows: "repeat(12, auto)",
      gridTemplateColumns: "repeat(24, auto)",
    };
    const videoControlBtn =
      this.props.attendeeMode === "video" ? (
        <span
          onClick={this.handleCamera}
          className="ag-btn videoControlBtn"
          title="Enable/Disable Video"
        >
          <i className="ag-icon ag-icon-camera"></i>
          <i className="ag-icon ag-icon-camera-off"></i>
        </span>
      ) : (
        ""
      );

    const audioControlBtn =
      this.props.attendeeMode !== "audience" ? (
        <span
          onClick={this.handleMic}
          className="ag-btn audioControlBtn"
          title="Enable/Disable Audio"
        >
          <i className="ag-icon ag-icon-mic"></i>
          <i className="ag-icon ag-icon-mic-off"></i>
        </span>
      ) : (
        ""
      );

    const switchDisplayBtn = (
      <span
        onClick={this.switchDisplay}
        className={
          this.state.streamList.length > 4
            ? "ag-btn displayModeBtn disabled"
            : "ag-btn displayModeBtn"
        }
        title="Switch Display Mode"
      >
        <i className="ag-icon ag-icon-switch-display"></i>
      </span>
    );
    const hideRemoteBtn = (
      <span
        className={
          this.state.streamList.length > 4 || this.state.displayMode !== "pip"
            ? "ag-btn disableRemoteBtn disabled"
            : "ag-btn disableRemoteBtn"
        }
        onClick={this.hideRemote}
        title="Hide Remote Stream"
      >
        <i className="ag-icon ag-icon-remove-pip"></i>
      </span>
    );
    const exitBtn = (
      <span
        onClick={this.handleExit}
        className={
          this.state.readyState ? "ag-btn exitBtn" : "ag-btn exitBtn disabled"
        }
        title="Exit"
      >
        <i className="ag-icon ag-icon-leave"></i>
      </span>
    );

    const startRecordingBtn = (
      <span
        onClick={this.startRecording}
        className={
          !this.state.isRecordingStarted && this.state.readyState
            ? "ag-btn"
            : "ag-btn disabled"
        }
        title="Start Recording"
        style={{ "font-size": "15px" }}
      >
        Start Recording
      </span>
    );

    const stopRecordingBtn = (
      <span
        onClick={this.stopRecording}
        className={
          this.state.isRecordingStarted && !this.state.isRecordingEnded
            ? "ag-btn"
            : "ag-btn disabled"
        }
        title="Stop Recording"
        style={{ "font-size": "15px" }}
      >
        Stop Recording
      </span>
    );

    return (
      <div id="ag-canvas" style={style}>
        <div className="ag-btn-group">
          {exitBtn}
          {videoControlBtn}
          {audioControlBtn}
          {this.isClient && startRecordingBtn}
          {this.isClient && stopRecordingBtn}
          {switchDisplayBtn}
          {hideRemoteBtn}
        </div>
      </div>
    );
  }
}

export default AgoraCanvas;
