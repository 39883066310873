import React, { useEffect, useState } from "react";
import ProjectPageLayout, {
  Content,
} from "../../../components/page-layout/PageLayout";
import HeaderButtons from "./HeaderButtons";
import Setup from "../setup/Setup";
import { Button, notification, Spin } from "antd";
import styles from "./new-survey-styles.module.css";
import Questions from "../questionsPage/Questions";
import Publish from "../publish/Publish";
import Criteria from "../criteria/Criteria";
import Participants from "../participants/Participants";
import axios, { Routes } from "../../../services/API";
import { useHistory, useParams } from "react-router-dom";
import handleNetworkErrors from "../handleNetworkErrors";
import { useCallback } from "react";
import useErrors from "./useErrors";
import { getClientId, notify, Sum } from "../../../utils";
import "./Button.css";
import { CostCounter } from "./CostCounter";
import { PERMISSION, ACCESS_LEVEL } from "../../../constants";
import ShareSurveyModal from "../published-survey/Modals/ShareSurveyModal/ShareSurveyModal";

const saveSurvey = async (survey) => {
  await axios({
    ...Routes.client.editSurvey(getClientId(), survey["_id"]),
    data: { data: survey },
  });
};

const sanitizeSurvey = (survey) => {
  const newGroups =
    survey.mode && survey.surveyType && survey.setting ? survey.groups : [];
  const newClientProvidedUsers = survey.useImportedUsers
    ? survey.clientProvidedUsers
    : [];
  const newPanelUsers = survey.usePanel ? survey.panelUsers : [];

  return {
    ...survey,
    groups: newGroups,
    clientProvidedUsers: newClientProvidedUsers,
    panelUsers: newPanelUsers,
  };
};

const getTotalGroupParticipants = (survey) => {
  if (!survey?.groups?.length) return 0;
  const groupsParticipants = survey.groups.map((group) =>
    group.numberOfParticipants ? group.numberOfParticipants : 0
  );
  return Sum(groupsParticipants);
};

const { client } = Routes;

const buttonStyles = {
  marginTop: "1.5em",
  marginBottom: "1.5em",
  backgroundColor: "#F2F2F2",
  fontSize: "large",
  borderRadius: 5,
  border: 0,
  color: "white",
  height: 51,
  width: 100,
};

const NewSurvey = ({
  getSurvey,
  updateSurvey,
  newSurveyCleanUp,
  updateSurveys,
  profile,
}) => {
  const history = useHistory();
  const [survey, setSurvey] = useState(null);
  const [step, setStep] = useState(1);
  const [adminQuestions, setAdminQuestions] = useState([]);
  const [influencers, setInfluencers] = useState([]);
  const [tierInfo, setTierInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [surveyPrice, setSurveyPrice] = useState(0);
  const [viewOnly, setViewOnly] = useState(false);
  const [openModToken, setOpenModToken] = useState('')
  const { formId } = useParams();
  const [totalGroupParticipants, setTotalGroupParticipants] = useState(
    getTotalGroupParticipants(survey)
  );
  const {
    setupErrors,
    criteriaErrors,
    questionsErrors,
    publishErrors,
    participantsErrors,
    branchingErrors,
  } = useErrors(survey, adminQuestions);
  const [showPublishSurveyModal, setShowPublishSurveyModal] = useState(false);

  const [creatorOnly, setCreatorOnly] = useState(
    profile?.accessLevel === ACCESS_LEVEL.create
  );

  useEffect(() => {
    setTotalGroupParticipants(getTotalGroupParticipants(survey));
  }, [survey]);

  useEffect(() => {
    setCreatorOnly(profile?.accessLevel === ACCESS_LEVEL.create);
  }, [profile?.accessLevel]);

  useEffect(() => {
    if (Array.isArray(survey?.shared)) {
      const clientId = localStorage.getItem("client");
      const sharedObj = survey.shared.find((s) => s.client === clientId);
      if (sharedObj) {
        setViewOnly(
          sharedObj.permission === PERMISSION.VIEW &&
            profile?.accessLevel !== ACCESS_LEVEL.admin
        );
      }
    }
  }, [survey, profile?.accessLevel]);

  useEffect(() => {
    document.body.setAttribute("data-new-theme", true);

    return () => {
      document.body.removeAttribute("data-new-theme");
    };
  }, []);

  const generateOpenModLink = async () => {
    const {url, method} = Routes.client.generateOpenModeratedLink(getClientId(), survey["_id"])
    try {
      debugger
      const {data} = await axios[method](url);
      return data.newSurveyLink.link
    } catch (err) {
      handleNetworkErrors(err);
    }
  }

  const publishSurveyHandler = async () => {
    await publishSurvey(survey, {
      setupErrors,
      criteriaErrors,
      questionsErrors,
      participantsErrors,
      publishErrors,
      branchingErrors,
    });
    setShowPublishSurveyModal(false);
  };
 
  const setFormQuestions = useCallback((questions) => {
    setSurvey((s) => ({ ...s, questions }));
  }, []);

  const setScreeningQuestions = useCallback((screeningQuestions) => {
    setSurvey((s) => ({ ...s, screeningQuestions }));
  }, []);

  const setBranchingRules = useCallback((rules) => {
    setSurvey((s) => ({ ...s, rules }));
  });

  const publishSurvey = useCallback(
    async (survey, errors) => {
      survey = sanitizeSurvey(survey);
      const id = getClientId();
      const { url, method } = creatorOnly
        ? client.sendForApproval(id, survey["_id"])
        : client.publishSurvey(id, survey["_id"]);
      const {
        setupErrors,
        criteriaErrors,
        questionsErrors,
        publishErrors,
        participantsErrors,
        branchingErrors,
      } = errors;
      if (setupErrors.length > 0) {
        notify("error", "Error", "You have errors in Setup.");
        return;
      }
      if (questionsErrors.length > 0) {
        notify("error", "Error", "You have errors in Questions");
        return;
      }
      if (criteriaErrors.length > 0) {
        notify("error", "Error", "You have errors in Criteria");
        return;
      }
      if (participantsErrors.length > 0) {
        notify("error", "Error", "You have errors in Participants.");
        return;
      }
      if (publishErrors.length > 0) {
        notify("error", "Error", "You have errors in Publish");
        return;
      }
      if (branchingErrors.length > 0) {
        notify("error", "Error", "You have errors in Branching Logic");
        return;
      }
      try {
        // setLoading(true);
        await saveSurvey(survey);
        const { data } = await axios[method](url);
        if (data && data.message && data.message.includes("Survey published")) {
          notification.success({
            message: "Survey published successfully.",
          });
        }
        if (data && data.message && data.message.includes("approval")) {
          notification.success({
            message: "Survey sent for approval.",
          });
        }
        updateSurveys();
        history.push("/client/published");
      } catch (err) {
        handleNetworkErrors(err);
      } finally {
        // setLoading(false);
      }
      // eslint-disable-next-line
    },
    [updateSurveys, creatorOnly]
  );

  useEffect(() => {
    if (survey?._id) saveSurvey(survey);
  }, [step]);

  useEffect(() => {
    if(survey?.surveyAccess && survey?.mode && survey?.surveyType) {
      if (survey?.openSurvey) {
        setOpenModToken(survey.openSurvey.surveyLinks[survey.openSurvey.surveyLinks.length-1].link)
      } else {
        if(!openModToken){
        generateOpenModLink().then((res)=> {
          setOpenModToken(res)
        })
      }
      }
    }
  }, [step])

  useEffect(() => {
    getSurvey(formId).then((data) => {
      if (data) setSurvey(data);
      else history.push("/client");
    });
    return () => newSurveyCleanUp();
    // eslint-disable-next-line
  }, [formId, getSurvey]);

  useEffect(() => {
    updateSurvey(survey);
  }, [survey, updateSurvey]);

  useEffect(() => {
    (async () => {
      const { url, method } = Routes.others.adminQuestions();
      try {
        const { data } = await axios[method](url);
        if (data) {
          setAdminQuestions(data.adminQuestions);
        }
      } catch (err) {
        handleNetworkErrors(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { url, method } = Routes.others.getInfluencerCategories();
      try {
        const { data } = await axios[method](url);
        if (data) {
          setInfluencers(data.influencerCategories);
        }
      } catch (err) {
        handleNetworkErrors(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { url, method } = Routes.others.tierInfo();
      try {
        const { data } = await axios[method](url);
        if (data) {
          setTierInfo(data);
        }
      } catch (err) {
        handleNetworkErrors(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (tierInfo && tierInfo.tiers[profile.accountTier]) {
      let price = CostCounter(survey, tierInfo.tiers[profile.accountTier]);
      setSurveyPrice(price);
    }
  }, [survey, updateSurvey]);

  const disableQuestionsPage = survey?.surveyType && survey.mode;
  const hasQuestions = survey?.questions?.length > 0;

  return (
    <>
      {survey && (
        <ProjectPageLayout noTop>
          <Content
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "stretch",
            }}
          >
            {loading && <Spin />}
            <div className={styles["h5"]} style={{ fontSize: "1.3rem" }}>
              {!!survey.name ? `${survey.name}` : "Create Survey"}
            </div>
            <HeaderButtons
              step={step}
              setStep={(val) => setStep(val)}
              setup={setupErrors.length}
              questions={questionsErrors.length + branchingErrors.length}
              criteria={criteriaErrors.length}
              participants={participantsErrors.length}
              publish={publishErrors.length}
              disableQuestionsPage={disableQuestionsPage}
            />
            {step === 1 && (
              <Setup
                formName={survey.name}
                setFormName={(name) => setSurvey((s) => ({ ...s, name }))}
                surveyAccess={survey.surveyAccess}
                surveyType={survey.surveyType}
                formQuestions={survey.questions}
                branchingRules={survey.rules}
                setFormQuestions={setFormQuestions}
                setSurveyType={(surveyType) =>
                  setSurvey((s) => ({ ...s, surveyType }))
                }
                setSurveyAccess={(surveyAccess) =>
                  setSurvey((s) => ({ ...s, surveyAccess }))
                }
                device={survey.device}
                setDevice={(device) => setSurvey((s) => ({ ...s, device }))}
                mode={survey.mode}
                setMode={(mode) => setSurvey((s) => ({ ...s, mode }))}
                setting={survey.setting}
                setSetting={(setting) => setSurvey((s) => ({ ...s, setting }))}
                timingType={survey.timingType}
                setTimingType={(timingType) =>
                  setSurvey((s) => ({ ...s, timingType }))
                }
                errors={setupErrors}
                questionsErrors={questionsErrors}
                surveyInstructions={survey.instructions}
                setSurveyInstructions={(instructions) => {
                  setSurvey((s) => ({ ...s, instructions }));
                }}
                brandName={survey.brandName}
                setBrandName={(brandName) => {
                  setSurvey((s) => ({ ...s, brandName }));
                }}
                brandLogo={survey.brandLogo}
                setBrandLogo={(brandLogo) => {
                  setSurvey((s) => ({ ...s, brandLogo }));
                }}
                guideFile={survey.guideFile}
                setGuideFile={(guideFile) => {
                  setSurvey((s) => ({ ...s, guideFile }));
                }}
                hasQuestions={hasQuestions}
                clearQuestions={() => {
                  setSurvey((s) => ({ ...s, questions: [] }));
                }}
                viewOnly={viewOnly}
              />
            )}
            {step === 2 && (
              <Questions
                formQuestions={survey.questions}
                branchingRules={survey.rules}
                surveyType={survey.surveyType}
                setFormQuestions={setFormQuestions}
                setBranchingRules={setBranchingRules}
                errors={questionsErrors}
                branchingErrors={branchingErrors}
                createdAt={survey.createdAt}
                brandLogo={survey.brandLogo}
                brandName={survey.brandName}
                surveyInstructions={survey.instructions}
                viewOnly={viewOnly}
              />
            )}
            {step === 3 && (
              <Criteria
                criteria={survey.criteria}
                adminQuestions={adminQuestions}
                setCriteria={(criteria) =>
                  setSurvey((s) => ({ ...s, criteria }))
                }
                surveyAccess={survey.surveyAccess}
                groups={survey.groups}
                setGroups={(groups) => setSurvey((s) => ({ ...s, groups }))}
                isGroupSurvey={
                  survey.mode && survey.surveyType && survey.setting
                }
                screeningQuestions={survey.screeningQuestions}
                setScreeningQuestions={setScreeningQuestions}
                errors={criteriaErrors}
                viewOnly={viewOnly}
              />
            )}
            {step === 4 && (
              <Participants
                useUserqualUsers={survey.useUserqualUsers}
                setUseUserqualUsers={(useUserqualUsers) =>
                  setSurvey((s) => ({ ...s, useUserqualUsers }))
                }
                useImportedUsers={survey.useImportedUsers}
                setUseImportedUsers={(useImportedUsers) =>
                  setSurvey((s) => ({ ...s, useImportedUsers }))
                }
                clientProvidedUsers={survey.clientProvidedUsers}
                setClientProvidedUsers={(clientProvidedUsers) =>
                  setSurvey((s) => ({ ...s, clientProvidedUsers }))
                }
                usePanel={survey.usePanel}
                setUsePanel={(usePanel) => {
                  setSurvey((s) => ({ ...s, usePanel }));
                }}
                panelUsers={survey.panelUsers}
                setPanelUsers={(panelUsers) => {
                  setSurvey((s) => ({ ...s, panelUsers }));
                }}
                errors={participantsErrors}
                viewOnly={viewOnly}
              />
            )}
            {step === 5 && (
              <Publish
                moderatedSlots={survey.moderatedSlots}
                setModeratedSlots={(moderatedSlots) =>
                  setSurvey((s) => ({ ...s, moderatedSlots }))
                }
                numberOfParticipants={survey.numberOfParticipants}
                setNumberOfParticipants={(numberOfParticipants) =>
                  setSurvey((s) => ({ ...s, numberOfParticipants }))
                }
                isGroupSurvey={
                  survey.mode && survey.surveyType && survey.setting
                }
                totalGroupParticipants={totalGroupParticipants}
                mode={survey.mode}
                setting={survey.setting}
                surveyType={survey.surveyType}
                errors={publishErrors}
                surveyPrice={surveyPrice}
                surveyAccess={survey.surveyAccess}
              />
            )}
            {step < 5 && (
              <Button
                className={styles["next-button"]}
                onClick={() =>
                  setStep((c) =>
                    disableQuestionsPage && c === 1 ? c + 2 : c + 1
                  )
                }
              >
                Next
              </Button>
            )}
            {step === 5 && creatorOnly && (
              <Button
                className={styles["next-button"]}
                onClick={() =>
                  publishSurvey(survey, {
                    setupErrors,
                    criteriaErrors,
                    questionsErrors,
                    participantsErrors,
                    publishErrors,
                    branchingErrors,
                  })
                }
                disabled={viewOnly}
              >
                {"Send for approval"}
              </Button>
            )}
            {step === 5 && !creatorOnly && (
              <Button
                className={styles["next-button"]}
                onClick={() => setShowPublishSurveyModal(true)}
                disabled={viewOnly}
              >
                {"Pay & Publish"}
              </Button>
            )}
            {showPublishSurveyModal && <ShareSurveyModal
              handleOk={publishSurveyHandler}
              handleCancel={() => setShowPublishSurveyModal(false)}
              isVisible={showPublishSurveyModal}
              surveyToken={survey.referSurveyToken}
              surveyAccess={survey.surveyAccess}
              surveyMode={survey.mode}
              surveyType={survey.surveyType}
              isPublish={true}
              survey={survey}
              token={openModToken}
            />
}
          </Content>
        </ProjectPageLayout>
      )}
    </>
  );
};

export default NewSurvey;
