import React, { Fragment, useEffect } from "react";
import Confused from "../../../components/user/confused/Confused";
import styles from "./publish.module.css";
import c from "classnames/bind";
import { Input, Tooltip } from "antd";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import { useState } from "react";
import Errors from "../new-survey/Errors";
const cx = c.bind(styles);

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const times = [
  { label: "8am", value: "08:00" },
  { label: "9am", value: "09:00" },
  { label: "10am", value: "10:00" },
  { label: "11am", value: "11:00" },
  { label: "12pm", value: "12:00" },
  { label: "1pm", value: "13:00" },
  { label: "2pm", value: "14:00" },
  { label: "3pm", value: "15:00" },
  { label: "4pm", value: "16:00" },
  { label: "5pm", value: "17:00" },
  { label: "6pm", value: "18:00" },
  { label: "7pm", value: "19:00" },
];

const DateDiv = ({ date, onClick, selected }) => {
  const [day, month, year] = date.split("/");
  return (
    <div
      className={cx("date-div")}
      onClick={() => onClick()}
      style={
        selected.isSelectedDate
          ? {
              backgroundColor: "#FF9480",
              borderColor: "#FF9480",
              color: "white",
            }
          : selected.isTimeSlotSelected
          ? {
              backgroundColor: "#FFA999",
              borderColor: "#FFA999",
              color: "white",
            }
          : {
              backgroundColor: "white",
              borderColor: "white",
              color: "black",
            }
      }
    >
      {`${day} ${months[parseInt(month) - 1]} ${year}`}
    </div>
  );
};

const TimeDiv = ({ time, onClick, selected }) => {
  return (
    <div
      className={cx("date-div")}
      onClick={() => onClick()}
      style={
        selected
          ? {
              backgroundColor: "#FF9480",
              borderColor: "#FF9480",
              color: "white",
            }
          : {
              backgroundColor: "white",
              borderColor: "white",
              color: "black",
            }
      }
    >
      {time}
    </div>
  );
};

const Publish = ({
  moderatedSlots,
  setModeratedSlots,
  numberOfParticipants,
  setNumberOfParticipants,
  isGroupSurvey,
  totalGroupParticipants,
  mode,
  setting,
  surveyType,
  errors,
  surveyPrice,
  viewOnly,
  surveyAccess
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const minimumDate = {
    ...utils().getToday(),
    day: utils().getToday().day + 1,
  };

  const dateToObject = (date) => {
    const res = {};
    date.forEach((item) => {
      const dateString = `${item.day}/${item.month}/${item.year}`;
      if (moderatedSlots && moderatedSlots[dateString])
        res[dateString] = moderatedSlots[dateString];
      else res[dateString] = {};
    });
    return res;
  };

  const objectToDate = (obj) => {
    const res = [];
    if (obj) {
      Object.keys(obj).forEach((item) => {
        const [day, month, year] = item.split("/");
        const date = {
          day: parseInt(day),
          month: parseInt(month),
          year: parseInt(year),
        };
        res.push(date);
      });
    }
    return res;
  };

  const getDateObject = (date, time) => {
    let [day, month, year] = date.split("/");
    month = month.length === 2 ? month : "0" + month;
    day = day.length === 2 ? day : "0" + day;
    const dateString = `${year}-${month}-${day}T${time}`;
    return new Date(dateString);
  };

  const isTimeSelected = (time) => {
    if (!selectedDate) return false;

    let res = false;
    if (moderatedSlots[selectedDate]) {
      Object.keys(moderatedSlots[selectedDate]).forEach((e) => {
        if (e === time) res = true;
      });
    }
    return res;
  };

  useEffect(() => {
    let slots = {};
    if (moderatedSlots && Object.keys(moderatedSlots).length > 0) {
      const currentDate = new Date();
      Object.keys(moderatedSlots).map((d, index) => {
        if (getDateObject(d, "00:00") > currentDate) {
          slots = {
            ...slots,
            [d]: moderatedSlots[d],
          };
        }
      });
      setModeratedSlots(slots);
    }
  }, []);

  return (
    <Fragment>
      <Confused style={{ width: "100%" }} />
      {!surveyAccess && surveyType == true && mode === true && setting === false ? (
        <div
          style={{
            backgroundColor: "#ECF9FA",
            padding: "1.5em",
            width: "100%",
            marginTop: "1.2em",
            pointerEvents: viewOnly ? "none" : "auto",
          }}
        >
          <div
            style={{ justifyContent: "center", alignItems: "flex-start" }}
            className={cx("lb-grid-container")}
          >
            <div style={{ justifySelf: "left" }}>
              <h3>
                Select/De-select dates{" "}
                <Tooltip title="You can select multiple dates">
                  <QuestionCircleOutlined />
                </Tooltip>
              </h3>
              <Calendar
                minimumDate={minimumDate}
                colorPrimary="#004479"
                shouldHighlightWeekends
                value={objectToDate(moderatedSlots)}
                onChange={(value) => {
                  value.sort((a, b) => (utils().isBeforeDate(a, b) ? -1 : 1));
                  setModeratedSlots(dateToObject(value));
                }}
                calendarClassName={cx("calendar")}
              />
            </div>

            {moderatedSlots && Object.keys(moderatedSlots).length > 0 ? (
              <div>
                <div className={cx("date-div-container")}>
                  {Object.keys(moderatedSlots).map((date, idx) => (
                    <DateDiv
                      key={idx}
                      date={date}
                      onClick={() => {
                        setSelectedDate(date);
                      }}
                      selected={{
                        isSelectedDate: selectedDate === date,
                        isTimeSlotSelected:
                          Object.keys(moderatedSlots[date]).length > 0,
                      }}
                    />
                  ))}
                </div>
              </div>
            ) : null}

            {selectedDate && Object.keys(moderatedSlots).length > 0 ? (
              <div>
                <div className={cx("date-div-container")}>
                  {times.map((e, i) => (
                    <TimeDiv
                      key={i}
                      time={e.label}
                      selected={isTimeSelected(e.value)}
                      onClick={() => {
                        if (selectedDate && moderatedSlots[selectedDate]) {
                          let timeExists = false;
                          const date = moderatedSlots[selectedDate];
                          Object.keys(date).forEach((time) => {
                            if (time === e.value) timeExists = true;
                          });
                          if (!timeExists)
                            moderatedSlots[selectedDate][e.value] = [];
                          else delete moderatedSlots[selectedDate][e.value];
                          setModeratedSlots(moderatedSlots);
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      <div
        className={cx("lb-container-col")}
        style={{ pointerEvents: viewOnly ? "none" : "auto" }}
      >
        <div className={cx("db-text")}>
          <h3>
            <span style={{ marginRight: "5px" }}>Number of Participants</span>
            {/* <Tooltip title="text">
              <QuestionCircleOutlined />
            </Tooltip> */}
          </h3>
          <p style={{ fontSize: "80%" }}>
            (We recommend atleast 5 users per survey)
          </p>
        </div>
        {!isGroupSurvey && (
          <div className={cx("input-container")}>
            <Input
              type="number"
              value={numberOfParticipants}
              onChange={(e) => setNumberOfParticipants(e.target.value)}
              className={cx("input-box")}
            />
            <PlusCircleOutlined
              className={cx("input-icon")}
              onClick={() =>
                setNumberOfParticipants((Number(numberOfParticipants) || 0) + 1)
              }
            />
            <MinusCircleOutlined
              className={cx("input-icon")}
              onClick={(e) => {
                e.stopPropagation();
                if (numberOfParticipants > 1)
                  setNumberOfParticipants(
                    (Number(numberOfParticipants) || 0) - 1
                  );
              }}
            />
          </div>
        )}
        {isGroupSurvey && (
          <div className={cx("db-text")}>
            <h3>{totalGroupParticipants}</h3>
          </div>
        )}
        {/* {mode === true && setting === true ? (
          <>
            <div className={cx("db-text")}>
              <h3>Number of groups</h3>
            </div>
            <div className={cx("input-container")}>
              <Input
                type="number"
                defaultValue={10}
                className={cx("input-box")}
              />
              <PlusCircleOutlined className={cx("input-icon")} />
              <MinusCircleOutlined className={cx("input-icon")} />
            </div>
          </>
        ) : null} */}
        <div className={cx("db-text")}>
          <h3>Price Per Participant</h3>
        </div>
        <div className={cx("db-text")}>
          <h3>Rs. {surveyPrice}</h3>
        </div>

        <div className={cx("db-text")}>
          <h3>Total</h3>
        </div>
        <div className={cx("db-text")}>
          <h3>
            Rs. {surveyPrice * numberOfParticipants}{" "}
            <span style={{ fontSize: "60%" }}>(Including GST)</span>
          </h3>
        </div>
      </div>
      <Errors errors={errors} />
    </Fragment>
  );
};

export default Publish;
