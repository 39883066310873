import React, { useEffect, useState } from "react";

import {
  COLOR,
  convertSecondsIntoStandardFormat,
  convertTranscriptToWebVtt,
  GetFilename,
} from "../../../../../constants";
import FlexRow from "../../../../../components/flex-row";
import Button from "../../../../../components/button/Button";
import {
  holoButtonStyle,
  inlineLabelStyle,
  darkBlueButtonStyle,
  dullGreenButtonStyle,
} from "../../constants/styles";
import styles from "./ResponseBox.module.css";
import { notification, Select, Slider, Card } from "antd";
import c from "classnames/bind";
import { StarFilled, StarOutlined, CloseOutlined } from "@ant-design/icons";
import { questionTypes } from "../../../../../components/constants/questionType";
import { Fragment } from "react";
import VideoModal, { Vmodal } from "./VideoModal/VideoModal";
import ImageModal from "./ImageModal/ImageModal";
import "./responseBox.css";
import {
  getRoleByToken,
  getVideoUrl,
  getTranscriptUrl,
  notify,
  incrementPendingRequests,
  decrementPendingRequests,
  getSubtitlesUrl,
  getModeratedSubtitlesUrl,
  getModeratedVideoUrl,
} from "../../../../../utils";
import WordCloudAndSentimentReport from "./WordCloudAndSentimentReport/WordCloudAndSentimentReport";
import axios, { Routes } from "../../../../../services/API";
import TranscriptModal from "./TranscriptModal/TranscriptModal";
import handleNetworkErrors from "../../../../user/constants/handleNetworkErrors";
import TranscriptVideoModal from "./TranscriptVideoModal/TransciptVideoModal";
import ReelsModal from "./ReelsModal/ReelsModal";
import EmotionalAnalysisModal from "./EmotionalAnalysisModal/EmotionalAnalysisModal";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { ErrorOutlined } from "@material-ui/icons";

const cx = c.bind(styles);

const ResponseBox = ({
  participantCriteriaDetails,
  participantDetails,
  participantResponse,
  survey,
  userId,
  sentimentAnalysis,
  participantIndex,
  transcriptReport,
  moderatedCallIndex

}) => {
  const { client } = Routes;
  const [viewScript, setViewScript] = useState(false);
  const [viewReels, setViewReels] = useState(false);
  const [transcript, setTranscript] = useState(null);
  const [transcriptText, setTranscriptText] = useState("");
  const [notes, setNotes] = useState(null);
  const [reels, setReels] = useState(null);
  const [subtitles, setSubtitles] = useState(null);
  const [responses, setResponses] = useState(null);
  const [userVideoRecording, setUserVideoRecording] = useState(false);
  const [userVideoURL, setUserVideoURL] = useState(null);
  const [emotionalAnalysis, setEmotionalAnalysis] = useState(false);
  const [emotionalAnalysisObject, setEmotionalAnalysisObject] = useState({});

  const userIdKey = survey.mode
    ? survey.qualitativeData[survey.setting ? 0 : participantIndex]?.slotKey
    : userId;

  const ClientLink = (redirectURL) => {
    const url = redirectURL.redirectURL;
    console.log(
      participantDetails,
      participantResponse,
      survey,
      userId,
      sentimentAnalysis,
      participantIndex,
      transcriptReport
    );
    return (
      <div className={cx("client-link")} style={{ textAlign: "center" }}>
        <Button
          style={{
            ...dullGreenButtonStyle,
            minWidth: "215px",
            marginBottom: "8px",
          }}
          onClick={() => {
            window.open(
              url.indexOf("https") > -1 ? url : "https://" + url,
              "_blank"
            );
          }}
        >{`Open link: ${url}`}</Button>
      </div>
    );
  };

  const videos = [
    {
      url:
        "https://userqual-file-upload.s3.amazonaws.com/newClip8O9hHs2klK.mp4",
      type: "video",
      altTag: "some image",
    },
    {
      url:
        "https://userqual-file-upload.s3.amazonaws.com/newClipB9DaKaq6W9.mp4",
      type: "video",
      title: "some video",
    },
    {
      url:
        "https://userqual-file-upload.s3.amazonaws.com/newClipaJQyanoC7F.mp4",
      type: "video",
      altTag: "some other image",
    },
    {
      url:
        "https://userqual-file-upload.s3.amazonaws.com/newClipaJQyanoC7F.mp4",
      type: "video",
      title: "some other video",
    },
  ];

  useEffect(() => {
    if (Array.isArray(transcript)) {
      const text = transcript.map(({ text }) => text).join(" ");
      setTranscriptText(text);
    }
  }, [transcript]);

  useEffect(() => {
    setResponses(questionResponses(participantResponse));
    // Only for Qualitative Surveys
    if (survey.surveyType) {
      setQualitativeData();
      saveVideoURL();
      // fetchSubtitleForVideo();
    }
  }, [participantResponse]);

  useEffect(() => {
    if (Array.isArray(transcript)) {
      setSubtitles(convertTranscriptToWebVtt(transcript));
    }
  }, [transcript]);

  const saveVideoURL = async () => {
    if (survey.surveyType) {
      const id =
        getRoleByToken(localStorage.getItem("token")) === "client"
          ? localStorage.getItem("client")
          : "";
      let { url, method } = client.getVideo(id, survey._id);
      url += fetchVideoUrl(survey, userId);
      try {
        const { data } = await axios[method](url);
        if (data) {
          console.log("video data", data);
          setUserVideoURL(data.signedRequest);
        }
      } catch (err) {
        handleNetworkErrors(err);
      }
    }
  };

  const fetchVideoUrl = (survey, userId) => {
    if (survey.mode) {
      if (survey.surveyAccess) return getModeratedVideoUrl(survey._id, survey.qualitativeData[moderatedCallIndex]?.slotKey, survey.surveyAccess);
      return getModeratedVideoUrl(survey._id, userIdKey, survey.surveyAccess);
    } else {
      return getVideoUrl(survey._id, userId);
    }
  };

  const setQualitativeData = async () => {
    const id =
      getRoleByToken(localStorage.getItem("token")) === "client"
        ? localStorage.getItem("client")
        : "";
    let { url, method } = client.fetchQualitativeData(
      id,
      survey._id,
      userIdKey
    );
    // url += getTranscriptUrl(survey._id, userId);
    try {
      const { data } = await axios[method](url);
      // const { subtitleData } = await axios[method](subtitleUrl);
      // console.log("subtitles", subtitleData);
      if (data) {
        setNotes((data.notes || []).sort((n1, n2) => n1.start - n2.start));
        setTranscript(
          (data.transcript || []).sort((t1, t2) => t1.start - t2.start)
        );
        setReels((data.reels || []).sort((r1, r2) => r1.start - r2.start));
      }
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  const fetchSubtitleForVideo = async () => {
    const id =
      getRoleByToken(localStorage.getItem("token")) === "client"
        ? localStorage.getItem("client")
        : "";
    let { url, method } = client.getTranscript(id, survey._id);
    if (survey.mode) {
      url += getModeratedSubtitlesUrl(survey._id, userIdKey);
    } else {
      url += getSubtitlesUrl(survey._id, userId);
    }
    try {
      const { data } = await axios[method](url);
      if (data) {
        setSubtitles(data);
      }
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  const saveTranscriptToTxt = () => {
    const element = document.createElement("a");
    const file = new Blob([transcriptText], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "transcript.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setViewScript(false);
  };

  const onViewEmotionalAnalysis = async () => {
    const uploadURL = `${survey._id}_${userIdKey.replace(/\//g, "|")}`;
    if (!survey.emotionJobId || !survey.emotionJobId[uploadURL]) {
      notify(
        "error",
        "Error",
        "No emotional analysis found for this participant."
      );
      return;
    }
    const jobId = survey.emotionJobId[uploadURL];
    const clientId = localStorage.getItem("client");
    try {
      let result = [];
      let nextToken = "";
      while (true) {
        const { data } = await axios({
          ...Routes.client.getVideoAnalysis(
            clientId,
            survey._id,
            jobId,
            nextToken
          ),
        });
        result = result.concat(data.Faces);
        if (data.NextToken) {
          nextToken = data.NextToken;
        } else {
          break;
        }
      }
      setEmotionalAnalysisObject(result);
      setEmotionalAnalysis(true);
    } catch (err) {
      notify(
        "error",
        "Error",
        "No emotional analysis found for this participant."
      );
    }
  };

  const updateTranscript = async (transcript) => {
    const id =
      getRoleByToken(localStorage.getItem("token")) === "client"
        ? localStorage.getItem("client")
        : "";
    try {
      const { data } = await axios({
        ...Routes.client.upsertQualitativeData(id, survey._id),
        data: {
          data: {
            slotKey: userIdKey,
            transcript: transcript,
          },
        },
      });
      setTranscript(transcript);
      notification.success({ message: "Transcript Updated Successfully" });
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  const updateNote = async (notes) => {
    const id =
      getRoleByToken(localStorage.getItem("token")) === "client"
        ? localStorage.getItem("client")
        : "";
    try {
      const { data } = await axios({
        ...Routes.client.upsertQualitativeData(id, survey._id),
        data: {
          data: {
            slotKey: userIdKey,
            notes: notes,
          },
        },
      });
      setNotes(notes);
      notification.success({
        message: "Notes Updated Successfully",
      });
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  const addReelToResultSection = async (reelUrl, { start, end, name = "" }) => {
    const id =
      getRoleByToken(localStorage.getItem("token")) === "client"
        ? localStorage.getItem("client")
        : "";
    try {
      const { data } = await axios({
        ...Routes.client.upsertQualitativeData(id, survey._id),
        data: {
          data: {
            slotKey: userIdKey,
            reel: {
              url: reelUrl,
              start: start,
              end: end,
              name: name,
            },
          },
        },
      });
      notification.success({
        message: "Reels Updated in Result Page Successfully",
      });
      // Fetch Updated Data
      setQualitativeData();
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  // const addReelToResultSection = async (start, end) => {
  //   const id =
  //     getRoleByToken(localStorage.getItem("token")) === "client"
  //       ? localStorage.getItem("client")
  //       : "";
  //   const startTime = convertSecondsIntoStandardFormat(start);
  //   const endTime = convertSecondsIntoStandardFormat(end);
  //   try {
  //     const { url, method, data } = Routes.client.createReel(id, survey._id);
  //     const updatedData = {
  //       ...data,
  //       inputs: [
  //         {
  //           fileName: `${survey._id}_${userId}.mp4`,
  //           clippings: [
  //             {
  //               start: startTime,
  //               end: endTime,
  //             },
  //           ],
  //         },
  //       ],
  //     };
  //     const resp = await axios[method](url, { data: updatedData });
  //     notification.success({
  //       message: "Reel Created",
  //     });
  //     updateReel(resp.data.url, { start, end });
  //   } catch (e) {
  //     handleNetworkErrors(e);
  //   }
  // };

  const createReel = async (start, end, name = "") => {
    const id =
      getRoleByToken(localStorage.getItem("token")) === "client"
        ? localStorage.getItem("client")
        : "";
    const startTime = convertSecondsIntoStandardFormat(start);
    const endTime = convertSecondsIntoStandardFormat(end);
    try {
      const { url, method, data } = Routes.client.createReel(id, survey._id);
      const updatedData = {
        ...data,
        inputs: [
          {
            fileName: `${survey._id}_${userIdKey.replace(/\//g, "|")}.mp4`,
            clippings: [
              {
                start: startTime,
                end: endTime,
              },
            ],
          },
        ],
      };
      const resp = await axios[method](url, { data: updatedData });
      // notification.success({
      //   message: "Reel Created",
      // });
      const reelUrl = resp.data.url;
      await addReelToResultSection(reelUrl, { start, end, name });
    } catch (e) {
      handleNetworkErrors(e);
    }
  };

  const removeReel = async (url, reelId = null) => {
    const filePath = GetFilename(url);
    const id =
      getRoleByToken(localStorage.getItem("token")) === "client"
        ? localStorage.getItem("client")
        : "";
    try {
      const { url, method } = Routes.client.removeReel(id, survey._id);
      const { message } = await axios[method](url, {
        data: {
          data: {
            reelId: reelId,
            filePath: filePath,
          },
        },
      });
      notification.success({
        message: "Reel deleted successfully",
      });
      setReels(reels.filter((reel) => reel._id !== reelId));
    } catch (err) {
      handleNetworkErrors(ErrorOutlined);
    }
  };

  const questionResponses = (participantResponse) => {
    const responseArray = participantResponse.map((question, index) => {
      let generateResponse = null;
      switch (question.type) {
        case questionTypes.QUESTION_INSTRUCTION:
        case questionTypes.IMAGE_INSTRUCTION:
        case questionTypes.VIDEO_INSTRUCTION:
          generateResponse = (
            <Fragment>
              {question.imageUrl ? (
                <div className={cx("image-video-block")}>
                  {/* <img src={question.imageUrl} /> */}
                  <ImageModal url={question.imageUrl} />
                </div>
              ) : null}
              {question.videoUrl ? (
                <div className={cx("image-video-block")}>
                  <VideoModal url={question.videoUrl} />
                </div>
              ) : null}
            </Fragment>
          );
          break;
        case questionTypes.TEXT:
        case questionTypes.IMAGE_COMMENT:
        case questionTypes.VIDEO_COMMENT:
          generateResponse = (
            <Fragment>
              {question.imageUrl ? (
                <div className={cx("image-video-block")}>
                  {/* <img src={question.imageUrl} /> */}
                  <ImageModal url={question.imageUrl} />
                </div>
              ) : null}
              {question.videoUrl ? (
                <div className={cx("image-video-block")}>
                  {/* <video src={question.videoUrl} /> */}
                  <VideoModal url={question.videoUrl} />
                </div>
              ) : null}
              <div className={cx("comment-box")}>{question.answer[0]}</div>
              <WordCloudAndSentimentReport
                participantIndex={participantIndex}
                sentimentReport={sentimentAnalysis[index]}
                wordReport={question.wordCloud}
              />
            </Fragment>
          );
          break;
        case questionTypes.MULTI_CHOICE:
        case questionTypes.IMAGE_MULTI_CHOICE:
        case questionTypes.VIDEO_MULTI_CHOICE:
          generateResponse = (
            <Fragment>
              {question.options.map((option, index) => {
                return (
                  <div key={index}>
                    <span
                      style={
                        index === question.answer[0]
                          ? { background: "#01223f" }
                          : null
                      }
                      className={cx("radio-buttons")}
                    ></span>
                    {option.imageUrl ? (
                      <div className={cx("image-video-block")}>
                        {/* <img src={option.imageUrl} /> */}
                        <ImageModal url={option.imageUrl} />
                      </div>
                    ) : null}
                    {option.videoUrl ? (
                      <div className={cx("image-video-block")}>
                        {/* <video src={option.videoUrl} /> */}
                        <VideoModal url={option.videoUrl} />
                      </div>
                    ) : null}
                    <div className={cx("labels")}>{option.label}</div>
                  </div>
                );
              })}
            </Fragment>
          );
          break;
        case questionTypes.CHECK_BOX:
        case questionTypes.IMAGE_CHECK_BOX:
        case questionTypes.VIDEO_CHECK_BOX:
          generateResponse = (
            <Fragment>
              {question.options.map((option, index) => {
                return (
                  <div key={index}>
                    <span
                      style={
                        question.answer.indexOf(index) > -1
                          ? { background: "#01223f" }
                          : null
                      }
                      className={cx("check-boxes")}
                    ></span>
                    {option.imageUrl ? (
                      <div className={cx("image-video-block")}>
                        {/* <img src={option.imageUrl} /> */}
                        <ImageModal url={option.imageUrl} />
                      </div>
                    ) : null}
                    {option.videoUrl ? (
                      <div className={cx("image-video-block")}>
                        {/* <video src={option.videoUrl} /> */}
                        <VideoModal url={option.videoUrl} />
                      </div>
                    ) : null}
                    <div className={cx("labels")}>{option.label}</div>
                  </div>
                );
              })}
            </Fragment>
          );
          break;
        case questionTypes.DROPDOWN:
          generateResponse = (
            <div>
              {question.answer[0]} (Range from 1-{question.range})
            </div>
          );
          break;
        case questionTypes.SLIDER:
          generateResponse = (
            <Fragment>
              <div style={{ padding: "40px 20px 0 20px" }}>
                <Slider
                  disabled
                  marks={{
                    [question.min]: question.min,
                    [question.max]: question.max,
                  }}
                  min={question.min}
                  max={question.max}
                  value={question.answer[0]}
                  tooltipVisible
                ></Slider>
              </div>
              <div className={cx("label-groups")}>
                <div>{question.labels.start}</div>
                <div>{question.labels.middle}</div>
                <div>{question.labels.end}</div>
              </div>
            </Fragment>
          );
          break;
        case questionTypes.LIKERT:
          generateResponse = (
            <Fragment>
              <div className={cx("radio-groups")}>
                {[...new Array(question.range)].map((tmp, index) => {
                  return (
                    <span
                      key={index}
                      style={
                        question.answer[0] - 1 === index
                          ? { background: "#01223f" }
                          : null
                      }
                      className={cx("radio-buttons")}
                    ></span>
                  );
                })}
              </div>
              <div className={cx("label-groups")}>
                <div>{question.labels.start}</div>
                <div>{question.labels.middle}</div>
                <div>{question.labels.end}</div>
              </div>
            </Fragment>
          );
          break;
        case questionTypes.NET_PROMOTOR_SCORE:
          generateResponse = (
            <Fragment>
              <div className={cx("square-groups")}>
                {[...new Array(11)].map((tmp, index) => {
                  return (
                    <div
                      key={index}
                      style={
                        index === question.answer[0]
                          ? { background: "#01223f", color: "white" }
                          : null
                      }
                    >
                      {index}
                    </div>
                  );
                })}
              </div>
              <div
                style={{ justifyContent: "space-between" }}
                className={cx("label-groups")}
              >
                <div>Not At All Likely</div>
                <div>Extremely Likely</div>
              </div>
            </Fragment>
          );
          break;
        case questionTypes.RANKING:
        case questionTypes.IMAGE_RANKING:
        case questionTypes.VIDEO_RANKING:
          generateResponse = (
            <Fragment>
              {question.answer.map((choice, index) => {
                return (
                  <div key={index}>
                    <span>{index + 1}- </span>
                    {choice.imageUrl ? (
                      <div className={cx("image-video-block")}>
                        {/* <img src={choice.imageUrl} /> */}
                        <ImageModal url={choice.imageUrl} />
                      </div>
                    ) : null}
                    {choice.videoUrl ? (
                      <div className={cx("image-video-block")}>
                        {/* <video src={choice.videoUrl} /> */}
                        <VideoModal url={choice.videoUrl} />
                      </div>
                    ) : null}
                    <div className={cx("labels")}>{choice.label}</div>
                  </div>
                );
              })}
            </Fragment>
          );
          break;
        case questionTypes.ASSOCIATION:
        case questionTypes.IMAGE_ASSOCIATION:
        case questionTypes.VIDEO_ASSOCIATION:
          generateResponse = (
            <Fragment>
              {question.answer.map((choice, index) => {
                return (
                  <div key={index}>
                    {choice.label ? <span>{choice.label}- </span> : null}
                    {choice.imageUrl ? (
                      <div className={cx("image-video-block")}>
                        {/* <img src={choice.imageUrl} /> */}
                        <ImageModal url={choice.imageUrl} />
                      </div>
                    ) : null}
                    {choice.videoUrl ? (
                      <div className={cx("image-video-block")}>
                        {/* <video src={choice.videoUrl} /> */}
                        <VideoModal url={choice.videoUrl} />
                      </div>
                    ) : null}
                    <div className={cx("labels")}>{choice.association}</div>
                  </div>
                );
              })}
            </Fragment>
          );
          break;
        case questionTypes.STAR_RATING:
        case questionTypes.IMAGE_RATING:
        case questionTypes.VIDEO_RATING:
          generateResponse = (
            <Fragment>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  float: "left",
                }}
              >
                {question.options.map((option, outIndex) => {
                  return (
                    <div key={index} className={cx("star-rating-options")}>
                      {option.imageUrl || option.videoUrl ? (
                        <Fragment>
                          {option.imageUrl ? (
                            <div className={cx("image-video-block")}>
                              {/* <img src={option.imageUrl} /> */}
                              <ImageModal url={option.imageUrl} />
                            </div>
                          ) : null}
                          {option.videoUrl ? (
                            <div className={cx("image-video-block")}>
                              {/* <video src={option.videoUrl} /> */}
                              <VideoModal url={option.videoUrl} />
                            </div>
                          ) : null}
                        </Fragment>
                      ) : null}
                      <div>
                        {option.label ? (
                          <div className={cx("labels")}>{option.label}</div>
                        ) : null}
                        <div>
                          {[...new Array(question.stars)].map((tmp, index) => {
                            let star = 0;
                            if (question.type === questionTypes.STAR_RATING) {
                              star = question.answer[outIndex];
                            } else {
                              star = question.answer[0][option.label];
                            }
                            return index + 1 <= star ? (
                              <StarFilled key={index} />
                            ) : (
                              <StarOutlined key={index} />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Fragment>
          );
          break;
      }
      return (
        <div key={index} className={cx("question-summary-box")}>
          <div className={cx("question")}>
            <div className={cx("question-number")}>
              <span>{index + 1}</span>
            </div>
            <div
              style={
                question.type === questionTypes.SLIDER
                  ? { width: "auto" }
                  : { width: "fit-content" }
              }
              className={cx("response-box")}
            >
              <div style={{ fontSize: 16, marginBottom: 10 }}>
                {question.text}
              </div>
              <div className={cx("options")}>
                {generateResponse}
                {question.clientLink !== "" ? (
                  <ClientLink redirectURL={question.clientLink} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      );
    });
    return responseArray;
  };

  return (
    <div
      style={{
        borderRadius: 5,
        width: "100%",
        fontSize: "14px",
      }}
    >
      {/* <FlexRow style={{ flexDirection: "column" }}>
        <div>
          <label style={inlineLabelStyle}>Started:</label>
          <span>
            {participantResponse[0].startedAt
              ? new Date(participantResponse[0].startedAt).toString()
              : "No Details are available"}
          </span>
        </div>
      </FlexRow>
      <FlexRow style={{ flexDirection: "column" }}> */}
      {/* <div>
          {participantDetails[participantIndex] &&
            participantDetails[participantIndex]
              .filter((detail) => ["Gender", "Age"].includes(detail.label))
              .map((detail, index) => {
                return (
                  <div key={index}>
                    <label style={inlineLabelStyle}>{detail.label}:</label>
                    <span>{detail.value}</span>
                  </div>
                );
              })}
        </div> */}
      {/* <div
          type="text"
          style={{
            color: COLOR.darkBlue,
            textDecoration: "underline",
            cursor: "pointer",
            margin: "10px 0",
          }}
          onClick={() => {
            setParticipantCriteriaDetails(!participantCriteriaDetails);
          }}
        >{` ${participantCriteriaDetails ? "View less" : "View all"
          } participant details`}</div> */}
      {/* </FlexRow> */}
      {participantCriteriaDetails && (
        <Card
          style={{
            borderRadius: "5px",
            fontSize: "0.8rem",
            marginBottom: "16px ",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "18px 0",
            }}
          >
            {participantDetails[participantIndex] &&
              participantDetails[participantIndex]
                .filter((detail) => !["Gender", "Age"].includes(detail.label))
                .map((detail, index) => {
                  return (
                    <div
                      className="flexObject"
                      style={{ display: "flex", flexDirection: "column" }}
                      key={index}
                    >
                      <div style={{ opacity: "80%", fontSize: "0.7rem" }}>
                        {detail.label}
                      </div>
                      <span>{detail.value}</span>
                    </div>
                  );
                })}
            <div
              className="flexObject"
              style={{ display: "flex", flexDirection: "column" }}
            >
              {/* <div style={{ opacity: "80%", fontSize: "0.7rem" }}>
                Color of Eyes
              </div>
              <span>Pink (Qualify)</span>
            </div>
            <div
              className="flexObject"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div style={{ opacity: "80%", fontSize: "0.7rem" }}>Degree</div>
              <span>Psychology (Qualify)</span>
            </div>
            <div
              className="flexObject"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div style={{ opacity: "80%", fontSize: "0.7rem" }}>Ended</div>
              <span>
                {participantResponse[0].endsAt
                  ? new Date(participantResponse[0].endsAt).toString()
                  : "No Details are available"}
              </span>
            </div>
            <div
              className="flexObject"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div style={{ opacity: "80%", fontSize: "0.7rem" }}>
                Time Spent
              </div>
              <span>
                {participantResponse[0].startedAt &&
                participantResponse[0].endsAt
                  ? new Date(
                      new Date(participantResponse[0].endsAt) -
                        new Date(participantResponse[0].startedAt)
                    )
                      .toISOString()
                      .substr(11, 8)
                  : "No Details are available"}
              </span>*/}
            </div> 
          </div>
        </Card>
      )}
      {survey.surveyType && (
        <Fragment>
          <Card style={{ borderRadius: "5px" }}>
            <div className="miscButtonsRow">
              <div
                className={"miscButtons"}
                onClick={() => setUserVideoRecording(true)}
              >
                View video recording
              </div>
              {userVideoRecording && userVideoURL && transcript && (
                <TranscriptVideoModal
                  visible={userVideoRecording}
                  onCancel={() => setUserVideoRecording(false)}
                  videoUrl={userVideoURL}
                  subtitles={subtitles}
                  transcript={transcript}
                  reels={reels}
                  createReel={createReel}
                  removeReel={removeReel}
                  notes={notes}
                  setNotes={setNotes}
                  setTranscript={setTranscript}
                  updateTranscript={updateTranscript}
                  updateNote={updateNote}
                  participantIndex={participantIndex}
                />
              )}
              {survey.surveyType && reels?.length > 0 && (
                <>
                  <div
                    className="miscButtons"
                    onClick={() => {
                      setViewReels(!viewReels);
                    }}
                  >
                    View video reels
                  </div>
                  {viewReels && (
                    <ReelsModal
                      visible={viewReels}
                      onCancel={() => setViewReels(false)}
                      reels={reels}
                      removeReel={removeReel}
                      participantIndex={participantIndex}
                    />
                    // {/* <div style={{ zIndex: 1 }}>
                    //   <ReactImageVideoLightbox
                    //     data={reels.map((reel, index) => {
                    //       return {
                    //         url: reel?.url,
                    //         title: "index",
                    //         type: "video",
                    //       };
                    //     })}
                    //     startIndex={0}
                    //     showResourceCount={true}
                    //     onCloseCallback={() => setViewReels(false)}
                    //     onNavigationCallback={(currentIndex) =>
                    //       console.log(`Current index: ${currentIndex}`)
                    //     }
                    //   />
                    // </div> */}
                  )}
                </>
              )}
              <div
                className="miscButtons"
                onClick={() => {
                  setViewScript(!viewScript);
                }}
              >
                View transcript
              </div>
              {viewScript && (
                <TranscriptModal
                  onClose={() => setViewScript(false)}
                  handleChange={() => saveTranscriptToTxt()}
                  transcript={transcriptText}
                  setTranscript={(text) => setTranscriptText(text)}
                ></TranscriptModal>
              )}
              <div
                className="miscButtons"
                onClick={() => onViewEmotionalAnalysis()}
              >
                View emotional analysis
              </div>
              {emotionalAnalysis && (
                <EmotionalAnalysisModal
                  isOpen={emotionalAnalysis}
                  data={emotionalAnalysisObject}
                  videoUrl={`https://userqual-video-recording.s3.ap-south-1.amazonaws.com/${
                    survey._id
                  }_${userIdKey.replace(/\//g, "|")}.mp4`}
                  closeModal={() => setEmotionalAnalysis(false)}
                  userId={userId}
                  survey={survey}
                />
              )}
              {transcriptReport[participantIndex] &&
                transcriptReport[participantIndex].sentiment && (
                  <WordCloudAndSentimentReport
                    sentimentReport={[
                      transcriptReport[participantIndex].sentiment,
                    ]}
                    wordReport={transcriptReport[participantIndex].wordCloud}
                  />
                )}
            </div>
          </Card>
        </Fragment>
      )}
      {responses}
    </div>
  );
};

export default ResponseBox;
