import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  SaveFilled,
  RollbackOutlined,
  UpCircleFilled,
  DownCircleFilled,
  FilterFilled,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { difference, cloneDeep } from "lodash";
import FlexRow from "../../../../components/flex-row";
import Button from "../../../../components/button/Button";
import { capitalizeFirstCharacter } from "../../../../utils";
import {
  greyButtonStyle,
  darkBlueButtonStyle,
  tealButtonStyle,
} from "../constants/styles";
import { getWordCloudAnalysis } from "../constants/getWordCloudReport";
import ResponseBox from "./ResponseBox";
import { questionTypes } from "../../../../components/constants/questionType";
import styles from "../QuestionSummary/QuestionSummary.module.css";
import c from "classnames/bind";
import filterUsers from "../constants/filterUsers";
import { defaultAdminQuestion } from "../../criteria/defaultCriteria";
import AddParticipantToPanel from "./ResponseBox/AddParticipantToPanel/AddParticipantToPanel";
import { Drawer, Space, Collapse, Checkbox } from "antd";

const { Panel } = Collapse;

const cx = c.bind(styles);

const IndividualResponses = ({
  surveyData,
  adminQuestions,
  criteria,
  setCriteria,
  moderatedCallIndex
}) => {
  const responseBoxRef = useRef();
  const [participant, setParticipant] = useState(1);
  const [filter, setFilter] = useState(false);
  const [participantResponses, setParticipantResponses] = useState([]);
  const [survey, setSurvey] = useState(surveyData);
  const [sentimentAnalysis, setSentimentAnalysis] = useState(null);

  const [isFilterActive, setIsFilterActive] = useState([]);
  const [comments, setComments] = useState(null);
  const [transcriptReport, setTranscriptReport] = useState([]);
  const [participantDetails, setParticipantDetails] = useState(null);

  const [activeKeys, setActiveKeys] = useState([]);
  const [filterAnswers, setFilterAnswers] = useState({});
  const [participantCriteriaDetails, setParticipantCriteriaDetails] = useState(
    false
  );

  useEffect(() => {
    setSurvey(surveyData);
    if (surveyData) {
      const responses = surveyData.responses;
      const qArray = [...isFilterActive];
      // if (responses && responses[0]) {
      //   responses[0].userId.adminQuestionsAnswers.map((question, index) => {
      //     const q = getQuestion(question.questionId);
      //     if (q && q.isActive) {
      //       qArray.push(q._id);
      //     }
      //   });
      // }
      if (surveyData.criteria.length === 0) {
        setIsFilterActive(defaultAdminQuestion);
      } else {
        surveyData.criteria.map((c, index) => {
          qArray.push(c.questionId);
        });
        defaultAdminQuestion.map((questionId, index) => {
          if (qArray.indexOf(questionId) === -1) {
            qArray.push(questionId);
          }
        });
        setIsFilterActive(qArray);
      }
      // setIsFilterActive(qArray);
      let storeComments = { ...comments };
      surveyData.questions.map((question, questionIndex) => {
        if (
          question.type === questionTypes.TEXT ||
          question.type === questionTypes.IMAGE_COMMENT ||
          question.type === questionTypes.VIDEO_COMMENT
        ) {
          storeComments[questionIndex] = [];
          surveyData.responses.map((response, index) => {
            if (!storeComments[questionIndex]) {
              storeComments[questionIndex] = [];
            }
            if (response?.answer?.[questionIndex] != null) {
              storeComments[questionIndex].push(
                response.answers[questionIndex][0]
              );
            }
          });
          // getWordCloudAnalysis(storeComments[questionIndex]);
        }
      });
      console.log("comments", storeComments);
    }
  }, [surveyData]);

  useEffect(() => {
    getFilteredUsers();
  }, [criteria]);

  useEffect(() => {
    if (filter) {
      setActiveKeys(criteria.map((c) => c.questionId));
      const filters = {};
      for (const { questionId, answer } of criteria) {
        filters[questionId] = [
          ...answer.map((a) => (Array.isArray(a) ? a.join() : a)),
        ];
      }
      setFilterAnswers(filters);
    }
  }, [criteria, filter]);

  const generateWordCloudData = (commentData) => {
    let words = {};
    if (commentData[1] && commentData[1].analysedComments?.[0]) {
      const report = commentData[1].analysedComments[0];
      report.wordCloud.map((wordReport, index) => {
        if (Object.keys(words).indexOf(wordReport.text) > -1) {
          words[wordReport.text] += wordReport.value;
        } else {
          words[wordReport.text] = wordReport.value;
        }
      });
    }
    let wordCloud = [];
    Object.keys(words).map((word, index) => {
      wordCloud.push({
        text: word,
        value: words[word],
      });
    });
    return wordCloud;
  };

  useEffect(() => {
    setParticipant(1);
  }, [survey]);

  useEffect(() => {
    debugger
    if (survey) {
      let questions = [];
      let responses = [];
      const isQualModeratedIndividualAndClosed =
        survey.surveyType && survey.mode && !survey.setting && !survey.surveyAccess;
      if (
        isQualModeratedIndividualAndClosed &&
        survey.qualitativeData[participant - 1]
      ) {
        questions = survey.qualitativeData[participant - 1].questions;
        responses = survey.qualitativeData[participant - 1].responses;
      } else if (
        !survey.surveyAccess &&
        survey.surveyType &&
        survey.mode &&
        survey.setting &&
        survey.qualitativeData[0]
      ) {
        questions = survey.qualitativeData[0].questions;
        responses = survey.qualitativeData[0].responses;
      } else if (survey.surveyAccess && survey.mode && survey.surveyType) {
        questions = survey.qualitativeData[moderatedCallIndex]?.questions;
        responses = survey.qualitativeData[moderatedCallIndex]?.responses;
        } else {
        questions = survey.questions;
        responses = survey.responses.filter((r) => r.answers.length > 0);
      }
      const sentimentValues = {};
      questions.map((question, questionIndex) => {
        if (
          [
            questionTypes.TEXT,
            questionTypes.IMAGE_COMMENT,
            questionTypes.VIDEO_COMMENT,
          ].indexOf(question?.type) > -1
        ) {
          const sentimentArray = [];
          responses.map((userResponse, index) => {
            const report = userResponse.answers[questionIndex][1];
            if (report && report.analysedComments?.[0]) {
              sentimentArray.push(report.analysedComments[0].sentiment);
            }
          });
          sentimentValues[questionIndex] = sentimentArray;
        }
        if (survey.surveyType) {
          const sentimentReports = [...transcriptReport];
          responses.map((userResponse, index) => {
            sentimentReports[index] = {
              wordCloud: userResponse.wordCloud,
              sentiment: userResponse.sentiment ? userResponse.sentiment : null,
            };
          });
          console.log("sentiment reports", sentimentReports);
          setTranscriptReport(sentimentReports);
        }
      });
      setSentimentAnalysis(sentimentValues);
      let participantDetails = [];
      const isQualModeratedIndividualWithNoResponses =
        isQualModeratedIndividual &&
        survey.qualitativeData[participant - 1] &&
        (!responses || responses.length === 0);
      setParticipantResponses(
        isQualModeratedIndividualWithNoResponses
          ? [[{}]]
          : responses.map((userResponse, index) => {
              console.log(userResponse, "userresponse");
              if(!surveyData.surveyAccess){
              const userDetails = [];
              userResponse.userId.adminQuestionsAnswers &&
                userResponse.userId.adminQuestionsAnswers.map(
                  (question, index) => {
                    if (
                      defaultAdminQuestion.indexOf(question.questionId) > -1
                    ) {
                      const q = getQuestion(question.questionId);
                      if (q.questionObject.questionType === "age") {
                        userDetails.push({
                          label: "Age",
                          value: question.answer[0],
                        });
                      } else {
                        userDetails.push({
                          label: q.clientField,
                          value:
                            q.questionObject &&
                            q.questionObject.options[question.answer[0]],
                        });
                      }
                      console.log("adminquestion", q);
                    }
                  }
                );
              participantDetails.push(userDetails);
                }
              return userResponse.answers.map((response, index) => {
                const questionInfo = questions[index];
                const participantResponse = {
                  type: questionInfo.type,
                  text: questionInfo.text,
                  clientLink: questionInfo.clientLink,
                  startedAt: userResponse.startedAt,
                  endsAt: userResponse.endsAt,
                };
                participantResponse.answer = response;
                switch (questionInfo.type) {
                  case questionTypes.QUESTION_INSTRUCTION:
                    break;
                  case questionTypes.IMAGE_INSTRUCTION:
                    participantResponse.imageUrl =
                      questionInfo.options.imageData;
                    break;
                  case questionTypes.IMAGE_COMMENT:
                    participantResponse.imageUrl =
                      questionInfo.options.imageData;
                    participantResponse.wordCloud = generateWordCloudData(
                      response
                    );
                    break;
                  case questionTypes.VIDEO_INSTRUCTION:
                    participantResponse.videoUrl =
                      questionInfo.options.videoData;
                    break;
                  case questionTypes.VIDEO_COMMENT:
                    participantResponse.videoUrl =
                      questionInfo.options.videoData;
                    participantResponse.wordCloud = generateWordCloudData(
                      response
                    );
                    break;
                  case questionTypes.TEXT:
                    participantResponse.wordCloud = generateWordCloudData(
                      response
                    );
                    break;
                  case questionTypes.SURVEY_BRIEF:
                    break;
                  case questionTypes.MULTI_CHOICE:
                  case questionTypes.CHECK_BOX:
                    participantResponse.options =
                      questionInfo.options.multiChoices;
                    break;
                  case questionTypes.IMAGE_MULTI_CHOICE:
                  case questionTypes.IMAGE_CHECK_BOX:
                    participantResponse.options = questionInfo.options.imageMultiChoices.map(
                      (choice) => {
                        return {
                          label: choice.imageLabel,
                          imageUrl: choice.imageData,
                        };
                      }
                    );
                    break;
                  case questionTypes.VIDEO_MULTI_CHOICE:
                  case questionTypes.VIDEO_CHECK_BOX:
                    participantResponse.options = questionInfo.options.videoMultiChoices.map(
                      (choice) => {
                        return {
                          label: choice.videoLabel,
                          videoUrl: choice.videoData,
                        };
                      }
                    );
                    break;
                  case questionTypes.RANKING:
                    break;
                  case questionTypes.IMAGE_RANKING:
                    participantResponse.answer = response.map((data, index) => {
                      return {
                        label: data.imageLabel,
                        imageUrl: data.imageData,
                      };
                    });
                    break;
                  case questionTypes.VIDEO_RANKING:
                    participantResponse.answer = response.map((data, index) => {
                      return {
                        label: data.videoLabel,
                        videoUrl: data.videoData,
                      };
                    });
                    break;
                  case questionTypes.STAR_RATING:
                    participantResponse.stars = questionInfo.options.stars;
                    participantResponse.starType =
                      questionInfo.options.starType;
                    participantResponse.options = response;
                    break;
                  case questionTypes.IMAGE_RATING:
                    participantResponse.stars = questionInfo.options.imageStar;
                    participantResponse.starType =
                      questionInfo.options.imageStarType;
                    participantResponse.options = questionInfo.options.imageStarRatingChoices.map(
                      (choice, index) => {
                        return {
                          label: choice.imageLabel,
                          imageUrl: choice.imageData,
                        };
                      }
                    );
                    break;
                  case questionTypes.VIDEO_RATING:
                    participantResponse.stars = questionInfo.options.videoStar;
                    participantResponse.starType =
                      questionInfo.options.videoStarType;
                    participantResponse.options = questionInfo.options.videoStarRatingChoices.map(
                      (choice, index) => {
                        return {
                          label: choice.videoLabel,
                          videoUrl: choice.videoData,
                        };
                      }
                    );
                    break;
                  case questionTypes.DROPDOWN:
                    participantResponse.range = questionInfo.options.range;
                    break;
                  case questionTypes.ASSOCIATION:
                    break;
                  case questionTypes.IMAGE_ASSOCIATION:
                    participantResponse.answer = response.map((data, index) => {
                      return {
                        imageUrl: data.imageData,
                        association: data.association,
                      };
                    });
                    break;
                  case questionTypes.VIDEO_ASSOCIATION:
                    participantResponse.answer = response.map((data, index) => {
                      return {
                        videoUrl: data.videoData,
                        association: data.association,
                      };
                    });
                    break;
                  case questionTypes.LIKERT:
                    participantResponse.labels = {
                      start: questionInfo.options.likertStartLabel,
                      middle: questionInfo.options.likertMiddleLabel,
                      end: questionInfo.options.likertEndLabel,
                    };
                    participantResponse.range =
                      questionInfo.options.likertRange;
                    break;
                  case questionTypes.SLIDER:
                    participantResponse.labels = {
                      start: questionInfo.options.sliderStartLabel,
                      middle: questionInfo.options.sliderMiddleLabel,
                      end: questionInfo.options.sliderEndLabel,
                    };
                    participantResponse.min = parseInt(
                      questionInfo.options.sliderMin
                    );
                    participantResponse.max = parseInt(
                      questionInfo.options.sliderMax
                    );
                    break;
                  case questionTypes.NET_PROMOTOR_SCORE:
                    participantResponse.options =
                      questionInfo.options.npsOption;
                    break;
                  case questionTypes.TEXT_AB_TEST:
                  case questionTypes.IMAGE_AB_TEST:
                  case questionTypes.VIDEO_AB_TEST:
                    break;
                }
                return participantResponse;
              });
            })
      );
      setParticipantDetails(participantDetails);
    }
  }, [survey, participant, moderatedCallIndex]);

  const resetFilterAndCompare = () => {
    setCriteria([]);
    setFilter(false);
    setSurvey(surveyData);
  };

  const getQuestion = (id) => {
    return adminQuestions.filter((e) => e["_id"] === id)[0];
  };

  const getAgeRange = ({ start, end, interval }) => {
    let range = [];
    let element = [];
    for (let i = start; i <= end; i++) {
      element.push(i);
      if (i % interval === 0) {
        range.push({
          label: `${element[0]}-${element[element.length - 1]}`,
          value: element,
        });
        element = [];
      }
    }
    return range;
  };

  console.log("participantResponse", participantResponses);

  const getFilteredUsers = () => {
    if(!surveyData.surveyAccess) {
    const users = surveyData.responses.map((response) => response.userId);
    const finalUsers = filterUsers(criteria, users);
    const userIds = finalUsers.map((user, index) => user._id);
    console.log("refinedUserIds", userIds, criteria);
    const refinedSurvey = { ...surveyData };
    refinedSurvey.responses = surveyData.responses.filter(
      (response, index) => userIds.indexOf(response.userId._id) > -1
    );
    setSurvey(refinedSurvey);
    } else {
      setSurvey({ ...surveyData })
    }
  };

  const applyCriteria = () => {
    const newCriteria = [];
    for (const key of activeKeys) {
      if (filterAnswers[key].length === 0) {
        continue;
      }
      const isAge =
        adminQuestions.find((q) => q._id === key)?.questionObject
          ?.questionType === "age";
      const answer = filterAnswers[key].map((a) =>
        isAge ? a.split(",").map((age) => parseInt(age, 10)) : a
      );
      newCriteria.push({
        questionId: key,
        answer,
      });
    }

    setCriteria(newCriteria);
  };

  const isQualModeratedIndividual =
    survey && survey.surveyType && survey.mode && !survey.setting && !surveyData.surveyAccess;
  const participants = isQualModeratedIndividual
    ? survey.qualitativeData
    : participantResponses;

  return (
    <Fragment>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <FlexRow style={{ justifyContent: "space-between" }}>
          <ReactToPrint
            trigger={() => (
              <Button style={darkBlueButtonStyle}>
                <SaveFilled style={{ fontSize: 18 }} />
              </Button>
            )}
            content={() => responseBoxRef.current}
          ></ReactToPrint>
          <Button style={darkBlueButtonStyle} onClick={resetFilterAndCompare}>
            <RollbackOutlined style={{ fontSize: 18 }} />
          </Button>
          <Button
            style={darkBlueButtonStyle}
            onClick={() => {
              setFilter(!filter);
            }}
          >
            <FilterFilled style={{ fontSize: 18 }} />
          </Button>
        </FlexRow>
      </div> */}
      <Drawer
        visible={filter}
        onClose={() => setFilter(false)}
        closable={false}
        headerStyle={{ padding: "8px 24px" }}
        title={"Filter"}
        destroyOnClose={true}
        width={340}
        extra={
          <Space>
            <Button
              type="text"
              style={{ color: "rgb(0, 35, 63)" }}
              onClick={() => setFilter(false)}
            >
              Cancel
            </Button>
            <Button
              type="text"
              style={{ color: "rgb(0, 35, 63)" }}
              onClick={() => {
                applyCriteria();
                setFilter(false);
              }}
            >
              Apply
            </Button>
          </Space>
        }
      >
        <Collapse
          ghost
          activeKey={activeKeys}
          expandIconPosition="right"
          destroyInactivePanel={true}
          expandIcon={({ isActive }) => {
            return isActive ? (
              <UpCircleFilled style={{ color: "rgb(160, 160, 160)" }} />
            ) : (
              <DownCircleFilled style={{ color: "rgb(160, 160, 160)" }} />
            );
          }}
          onChange={(openKeys) => {
            const addedKeys = difference(openKeys, activeKeys);
            const removedKeys = difference(activeKeys, openKeys);
            const tempAnswers = cloneDeep(filterAnswers);
            for (const key of addedKeys) {
              tempAnswers[key] = [];
            }
            for (const key of removedKeys) {
              delete tempAnswers[key];
            }
            setActiveKeys(openKeys);
            setFilterAnswers(tempAnswers);
          }}
        >
          {adminQuestions &&
            adminQuestions
              .filter(
                (e) =>
                  e.isActive === true &&
                  e.criteriaType === false &&
                  !!e.clientField &&
                  isFilterActive.indexOf(e["_id"]) > -1 &&
                  [
                    "multipleChoice",
                    "checkboxes",
                    "location",
                    "checkboxesSpecial",
                    "age",
                  ].includes(e.questionObject.questionType)
              )
              .map((e, i) => (
                <Panel
                  key={e["_id"]}
                  header={capitalizeFirstCharacter(e.clientField)}
                  className={cx("panel-container")}
                >
                  <Checkbox.Group
                    value={filterAnswers[e._id]}
                    onChange={(checkedValue) => {
                      const tempAnswers = cloneDeep(filterAnswers);
                      tempAnswers[e._id] = checkedValue;
                      setFilterAnswers(tempAnswers);
                    }}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: 8,
                    }}
                  >
                    {e.questionObject.questionType === "age"
                      ? getAgeRange({
                          start: e.questionObject.start,
                          end: e.questionObject.end,
                          interval: e.questionObject.interval,
                        }).map((op, idx) => (
                          <Checkbox
                            key={`filter-${e._id}-${idx}`}
                            value={op.value.join()}
                            style={{ margin: 0 }}
                          >
                            {op.label}
                          </Checkbox>
                        ))
                      : e.questionObject.options.map((op, idx) => (
                          <Checkbox
                            key={`filter-${e._id}-${idx}`}
                            value={idx}
                            style={{ margin: 0 }}
                          >
                            {op}
                          </Checkbox>
                        ))}
                  </Checkbox.Group>
                </Panel>
              ))}
        </Collapse>
      </Drawer>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "30% 68%",
          columnGap: "15px",
        }}
      >
        <div className={cx("grey-container")} style={{ height: "fit-content" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <div style={{ fontWeight: "bold", margin: "auto 0" }}>
              Participants
            </div>
            <div
              style={{
                display: "flex",
                backgroundColor: "#B9E8F6",
                gap: "16px",
                borderRadius: "5px",
                padding: "8px",
              }}
            >
              <RollbackOutlined
                onClick={resetFilterAndCompare}
                style={{ fontSize: 14, margin: "auto", cursor: "pointer" }}
              />
              <FilterFilled
                style={{ fontSize: 14, margin: "auto", cursor: "pointer" }}
                onClick={() => {
                  setFilter(!filter);
                }}
              />
            </div>
          </div>
          <div
            style={{
              maxHeight: "450px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {participants.map((prtcpnt, index) => {
              return (
                <div
                  className={
                    participant == index + 1
                      ? cx("question-container-selected")
                      : cx("question-container")
                  }
                  onClick={() => setParticipant(index + 1)}
                >
                  <div style={{ width: "92%" }}>
                    <div style={{ opacity: "80%", padding: "10px" }}>
                      {" "}
                      Participant {index + 1}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {participantResponses[
          isQualModeratedIndividual ? 0 : participant - 1
        ] ? (
          <div
            ref={responseBoxRef}
            style={{
              backgroundColor: "#FAFAFA",
              padding: 20,
              width: "100%",
              borderRadius: 8,
            }}
          >
            <div>
              <FlexRow style={{ justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: "10px", margin: "auto 0" }}>
                  <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    User {participant}
                  </div>
                  {participantDetails[participant - 1]?.length ? (
                    <div
                      style={{
                        fontSize: "0.8rem",
                        opacity: "80%",
                        margin: "auto 0",
                      }}
                    >
                      {
                        participantDetails[participant - 1].find(
                          (a) => a.label == "Gender"
                        )?.value
                      }{" "}
                      of{" "}
                      {
                        participantDetails[participant - 1].find(
                          (a) => a.label == "Age"
                        )?.value
                      }{" "}
                      years
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* <Button
                  style={{
                    ...(participants.length > 1
                      ? tealButtonStyle
                      : greyButtonStyle),
                    marginLeft: 0,
                  }}
                >{`Participant #${participant}`}</Button> */}
                <div>
                  <Button
                    style={
                      participant === 1 ? greyButtonStyle : darkBlueButtonStyle
                    }
                    disabled={
                      !(participants && participants[participant - 1]) ||
                      participant === 1
                    }
                    onClick={() => {
                      if (participant > 1) {
                        setParticipant(participant - 1);
                      }
                    }}
                    icon={<CaretLeftOutlined />}
                  />
                  <Button
                    style={
                      !(participants && participants[participant - 1]) ||
                      participant === participants.length
                        ? greyButtonStyle
                        : darkBlueButtonStyle
                    }
                    onClick={() => {
                      if (true) {
                        setParticipant(participant + 1);
                      }
                    }}
                    disabled={
                      !(participants && participants[participant - 1]) ||
                      participant === participants.length
                    }
                    icon={<CaretRightOutlined />}
                  />
                </div>
              </FlexRow>
              <div style={{ display: "flex", gap: "20px" }}>
                <Button
                  size={"default"}
                  style={{
                    ...darkBlueButtonStyle,
                    backgroundColor: "#B9E8F6",
                    color: "black",
                    border: "none",
                    marginRight: 0,
                  }}
                  onClick={() => {
                    setParticipantCriteriaDetails(!participantCriteriaDetails);
                  }}
                >
                  {` ${participantCriteriaDetails ? "Hide" : "View"} details`}
                </Button>
                <AddParticipantToPanel
                  userId={
                    survey.responses[participant - 1] &&
                    survey.responses[participant - 1].userId &&
                    survey.responses[participant - 1].userId._id
                      ? survey.responses[participant - 1].userId._id
                      : null
                  }
                />
                <ReactToPrint
                  trigger={() => (
                    <Button
                      size={"default"}
                      style={{
                        ...darkBlueButtonStyle,
                        backgroundColor: "#B9E8F6",
                        color: "black",
                        border: "none",
                        marginRight: 0,
                      }}
                    >
                      Save as file
                    </Button>
                  )}
                  content={() => responseBoxRef.current}
                ></ReactToPrint>
              </div>
            </div>
            <ResponseBox
              participantCriteriaDetails={participantCriteriaDetails}
              participantResponse={
                participantResponses[
                  isQualModeratedIndividual ? 0 : participant - 1
                ]
              }
              survey={survey}
              participantIndex={participant - 1}
              sentimentAnalysis={sentimentAnalysis}
              participantDetails={participantDetails}
              transcriptReport={transcriptReport}
              userId={
                survey.responses[participant - 1] &&
                survey.responses[participant - 1].userId &&
                survey.responses[participant - 1].userId._id
                  ? survey.responses[participant - 1].userId._id
                  : null
              }
              moderatedCallIndex = {moderatedCallIndex}
            />
          </div>
        ) : (
          <div
            style={{
              fontSize: "24px",
              color: "rgb(0, 68, 121)",
              backgroundColor: "#f5f5f5",
              padding: 20,
              width: "100%",
              borderRadius: 8,
            }}
          >
            No Result Found for given Criteria!
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default IndividualResponses;
