import React, { Fragment, useState } from "react";
import { styled } from "@linaria/react";
// import c from "classnames/bind";
import { Flex } from "../draft/DraftFormBox";
import {
  MobileOutlined,
  DesktopOutlined,
  EyeOutlined,
  TeamOutlined,
  UserOutlined,
  LinkOutlined,
  VideoCameraOutlined,
  FileTextOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UsergroupAddOutlined,
  StopOutlined,
  GroupOutlined,
} from "@ant-design/icons";
import Survey from "../../user/survey/Survey";
import moment from "moment";
import { Button, notification, Row } from "antd";
import { useHistory } from "react-router-dom";
import SurveyStateModal from "./Modals/SurveyStateModal/SurveyStateModal";
import { surveyStates } from "../../../constants";
import {
  getRoleByToken,
  incrementPendingRequests,
  getDateObject,
  getClientInformation,
} from "../../../utils";
import axios, { Routes } from "../../../services/API";
import styles from "./PublishedFormBoxNewCopy.module.css";
import c from "classnames/bind";
import handleNetworkErrors from "../handleNetworkErrors";
import images from "./publishedFormBoxImages";
import ShareSurveyModal from "./Modals/ShareSurveyModal/ShareSurveyModal.jsx";
import { useEffect } from "react";
import { Group } from "@material-ui/icons";

const cx = c.bind(styles);

const ScheduledCallsBox = styled.div``;

const PublishedFormBoxRefactoredCopy = ({
  survey,
  surveyCost,
  surveyId,
  updateSurveys,
  name,
  surveyType,
  mode,
  accountUsersInfo,
  device,
  questions,
  setting,
  timingType,
  updatedAt,
  numberOfParticipants,
  numberOfResponses,
  isAdminVerified,
  onScheduledCallsClick,
  setOpenToken
}) => {
  const [showModal, setShowModal] = useState(false);
  const [surveyState, setSurveyState] = useState(survey.status);
  const [shareSurveyModal, setShareSurveyModal] = useState(false);
  const [surveyStateModal, setSurveyStateModal] = useState(false);
  const [hover, setHover] = useState(null);
  const [createdBy, setCreatedBy] = useState("");

  useEffect(() => {
    if (!accountUsersInfo) return setCreatedBy("");
    const user = accountUsersInfo.find(
      (user) => user.clientId === survey.clientId
    );
    if (!user) return setCreatedBy("");
    return setCreatedBy(
      user.firstName ? user.firstName + " " + user.lastName : user.email
    );
  }, [survey, accountUsersInfo]);

  const history = useHistory();

  const getStatus = () => {
    if (!isAdminVerified) return "PENDING";
    if (numberOfResponses === numberOfParticipants) return "COMPLETED";
    return "IN PROGRESS";
  };

  const updateSurvey = async (values) => {
    const id =
      getRoleByToken(localStorage.getItem("token")) === "client"
        ? localStorage.getItem("client")
        : "";
    try {
      const { data } = await axios({
        ...Routes.client.editSurvey(id, survey["_id"]),
        data: {
          data: values,
        },
      });
      notification.success({
        message: "Survey updated successfully",
      });
      updateSurveys();
    } catch (err) {
      handleNetworkErrors(err);
    }
  };

  let hasScheduledCalls = (() => {
    const { moderatedSlots } = survey;
    const now = new Date();
    if (moderatedSlots) {
      for (const date in moderatedSlots) {
        for (const time in moderatedSlots[date]) {
          if (
            moderatedSlots[date][time].length > 0 &&
            now < getDateObject(date, time)
          ) {
            return true;
          }
        }
      }
    }
    return false;
  })();

  let hasResults = numberOfResponses > 0;

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={cx("published-box")}
    >
      <div className={cx("firstSection")}>
        <div className={cx("header")}>
          <div
            style={{
              backgroundColor:
                getStatus() === "PENDING"
                  ? "#BDBDBD"
                  : getStatus() === "IN PROGRESS"
                  ? "#F09985"
                  : "#8EDAD0",
            }}
            className={cx("survey-progress")}
          >
            {getStatus() + " " + numberOfResponses + "/" + numberOfParticipants}
          </div>
          <div className={cx("action-buttons")}>
            <div>
              <LinkOutlined
                style={{ color: "white" }}
                onClick={() => setShareSurveyModal(true)}
                disabled={surveyStates.IN_PROGRESS}
                title={"Share Survey"}
              />
            </div>
            {!(survey.surveyType && survey.mode) && (
              <div>
                <EyeOutlined
                  style={{
                    fontSize: 20,
                    cursor: "pointer",
                    color: "white",
                    verticalAlign: "middle",
                  }}
                  title={"View Survey"}
                  onClick={() => setShowModal(true)}
                />
              </div>
            )}
            <div>
              <img
                onClick={() => setSurveyStateModal(true)}
                src={
                  surveyStates.IN_PROGRESS === surveyState
                    ? images.stop
                    : images.play
                }
                title={
                  surveyStates.IN_PROGRESS === surveyState
                    ? "Survey Live"
                    : surveyStates.PAUSE === surveyState
                    ? "Survey Paused"
                    : "Survey Ended"
                }
              />
            </div>
          </div>
        </div>
        <div className={cx("surveyDetails")}>
          <div
            style={{
              fontSize: 16,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflowX: "auto",
            }}
          >
            <b>{name}</b>
          </div>
          <div style={{ fontSize: 14 }}>
            {moment(updatedAt).format("DD MMMM YYYY")}
          </div>
          <div style={{ fontSize: 14 }}>Created by: {createdBy}</div>
          {hover && (
            <div className={cx("survey-icons")}>
              <div>
                <span title={surveyType ? "Qualitative Survey" : "Quantative"}>
                  {surveyType ? (
                    <VideoCameraOutlined></VideoCameraOutlined>
                  ) : (
                    <FileTextOutlined></FileTextOutlined>
                  )}
                </span>
              </div>
              <div>
                <span title={mode ? "Moderated" : "UnModerated"}>
                  {mode ? (
                    <UserAddOutlined></UserAddOutlined>
                  ) : (
                    <UserDeleteOutlined></UserDeleteOutlined>
                  )}
                </span>
              </div>
              <div>
                <span title={setting ? "Group" : "Individual"}>
                  {mode ? (
                    <UsergroupAddOutlined></UsergroupAddOutlined>
                  ) : (
                    <UserOutlined></UserOutlined>
                  )}
                </span>
              </div>
              {surveyType === true && timingType !== undefined && (
                <span style={{ display: "flex", flexDirection: "row" }}>
                  {timingType === 1 && <strong>15</strong>}
                  {timingType === 2 && <strong>30</strong>}
                  {timingType === 3 && <strong>45</strong>}{" "}
                  <div style={{ paddingLeft: "3px" }}>mins</div>
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={cx("secondSection")}>
        <div>
          {isAdminVerified === true && surveyType === true && mode === true && !survey.surveyAccess && (
            <div
              onClick={() => onScheduledCallsClick(surveyId)}
              className={
                hasScheduledCalls
                  ? cx(["schedule-call-button", "button"])
                  : cx(["schedule-call-button", "button", "disabled-button"])
              }
              title="Scheduled Calls"
            >
              {hasScheduledCalls ? "SCHEDULED CALLS" : "NO CALLS SCHEDULED"}
            </div>
          )}
        </div>
        <div>
          {isAdminVerified === true && surveyType === true && mode === true && survey.surveyAccess && (
            <div
              onClick={() => onScheduledCallsClick(surveyId)}
              className={
                cx(["schedule-call-button", "button"])
              }
              title="Scheduled Calls"
            >
              JOIN CALL
            </div>
          )}
        </div>
        <div
          onClick={() => history.push("/client/results", { surveyId })}
          className={
            hasResults
              ? cx(["view-result-button", "button"])
              : cx(["view-result-button", "button", "disabled-button"])
          }
          title="View Results"
        >
          {hasResults ? "VIEW RESULTS" : "NO RESULTS"}
        </div>
      </div>
      {shareSurveyModal && (
        <ShareSurveyModal
          handleCancel={() => setShareSurveyModal(false)}
          handleOk={() => {
            setShareSurveyModal(false);
          }}
          isPublish={false}
          surveyToken={survey.referSurveyToken}
          surveyAccess={survey.surveyAccess}
          clientId={survey.clientId}
          surveyId = {survey._id}
          isVisible={shareSurveyModal}
          surveyType={surveyType}
          surveyMode = {survey.mode}
          survey={survey}
          setOpenToken={setOpenToken}
        />
      )}
      {showModal && (
        <Survey
          isOpen={showModal}
          questions={questions}
          rules={survey && survey.rules ? survey.rules : []}
          surveyType={surveyType}
          onClose={() => setShowModal(false)}
          tryOwnForm={true}
          brandName={survey.brandName}
          brandLogo={survey.brandLogo}
          surveyInstructions={survey.instructions}
        />
      )}
      {surveyStateModal && (
        <SurveyStateModal
          surveyCost={surveyCost}
          handleCancel={() => setSurveyStateModal(false)}
          handleOk={(state) => {
            setSurveyStateModal(false);
            updateSurvey({
              status: state,
            });
          }}
          surveyState={surveyState}
          updateSurvey={updateSurvey}
          survey={survey}
          isVisible={surveyStateModal}
        />
      )}
    </div>
  );
};

export default PublishedFormBoxRefactoredCopy;
